<template>
  <div class="wrapper" ref="top">
    <section v-if="loadFlag">
      <div class="container-small">
        <h1 class="title page-title separator">FAQ</h1>
        <div class="tab-wrapper">
          <div class="tab-content" @click="tabFlag = 'artist'"
               :style="{color: tabFlag === 'artist' ? '#FF0090' : '#A6A6A6',
                          'border-color': tabFlag === 'artist' ? '#FF0090' : '#A6A6A6'
                          }"
          >For Artist
          </div>
          <div class="tab-content" @click="tabFlag = 'investor'"
               :style="{color: tabFlag === 'investor' ? '#FF0090' : '#A6A6A6',
                          'border-color': tabFlag === 'investor' ? '#FF0090' : '#A6A6A6'
                          }">For Investor
          </div>
        </div>
        <div class="faq-wrapper">
          <div class="faq-investor-wrap" v-if="tabFlag === 'investor'">
            <FAQInvestor v-for="(item, index) in investor" :key="index" :item="item"/>
          </div>
          <div class="faq-artist-wrap" v-if="tabFlag === 'artist'">
            <FAQArtist v-for="(item, index) in artist" :key="index" :item="item"/>
          </div>
        </div>
      </div>
    </section>
    <div class="preloader-wrapper" v-else>
      <v-progress-circular
          :size="75"
          :width="8"
          color="#FF0090"
          indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import FAQInvestor from "@/components/static/FAQInvestor.vue";
import FAQArtist from "@/components/static/FAQArtist.vue";

export default {
  name: "FAQ",
  data() {
    return {
      faq: [],
      tabFlag: 'artist',
      loadFlag: true,
      showFlag: false,
      investor: null,
      artist: null
    }
  },
  computed: {},
  methods: {
    dataParse(data, tabName) {
      return data.filter(i => i.categoryName === tabName)
    },
    goTo(refName) {
      let element = this.$refs[refName]
      element.scrollIntoView({behavior: 'smooth'})
    },
    checkQuestions() {
      this.$load(async () => {
        this.loadFlag = false
        let response = (await this.$api.FAQ.getSearch())
        this.faq = response.data.body.elements
        this.investor = this.dataParse(this.faq, 'INVESTOR')
        this.artist = this.dataParse(this.faq, 'ARTIST')
        this.loadFlag = true
      })
    }
  },
  watch: {},
  mounted() {

    this.checkQuestions()
    document.title = 'FAQ'

    this.goTo('top')
  },
  components: {FAQInvestor, FAQArtist}
}
</script>

<style scoped>

.faq-wrapper {
  width: 100%;
}

.faq-investor-wrap {
  width: 100%;
}

.tab-wrapper {
  display: flex;
}

.tab-content {
  width: 50%;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: 3px solid;
  cursor: pointer;
}


@media only screen and (max-width: 600px) {

  .faq-item-header-wrap svg {
    width: 13px;
    height: 6px;
  }
}

</style>