<template>
  <div class="page-wrapper">
    <div class="wrapper" v-if="isLoaded">
      <div class="" v-if="type === 'KYC'">
        <KYC/>
      </div>
      <div class="" v-if="type === 'FAQ'">
        <FAQ/>
      </div>
    </div>
  </div>
</template>

<script>
import LocalStorageService from "@/services/LocalStorageService";
import KYC from "@/components/mobile-application/KYC.vue"
import FAQ from "@/components/mobile-application/FAQ.vue"

export default {
  name: "MobileApplication",
  components: {
    KYC,
    FAQ
  },
  data() {
    return {
      isLoaded: false,
      userUuid: '',
    }
  },
  computed: {
    type() {
      return this.$route.query.type;
    }
  },
  methods: {
    async getTokens() {
      this.isLoaded = false
      //
      // const tmpToken = await this.$api.auth.tmpToken()
      // console.log('tmpToken: ', tmpToken.data.body.value)

      const params = {
        authToken: this.$route.query.token
      }
      const {accessToken, refreshToken} = (await this.$api.auth.getNewTokens(params)).data.body
      LocalStorageService.setToken(accessToken, refreshToken)
      this.isLoaded = true
      //http://localhost:8080/mob-app?type=KYC&token=83c8cd7c-4f56-4c3c-b54d-787fd4d62a8c&uuid=2762787b-0738-40ce-ba7d-b1bf6e886de0
    }
  },
  created() {
    const localStorageToken = localStorage.getItem('access_token')
    if (!localStorageToken && this.$route.query.type === 'KYC') {
      this.getTokens()
    }
  }
}
</script>

<style scoped>
.page-wrapper {
  display: flex;
  justify-content: center;
}
</style>