<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">

      <div class="profile">
        <Sidebar/>


        <div class="profile-content">
          <div class="wallet-header">
            <h1 class="title page-title separator">WALLET</h1>
          </div>
          <div class="wallet-body-wrapper" v-if="loadFlag">
            <div class="wallet-current-balance">
              <div class="wallet-current-balance__amount">
                <h3 class="current-balance-amount__title">Cash balance</h3>
                <div class="current-balance-amount__amount">
                  {{ walletTotalAmount + ' €' }}
                </div>
                <div class="current-balance-amount__funds-and-bonuses">
                  <div class="current-balance-amount__funds">
                    <span>Personal funds:</span><span>{{ `${walletCurrency} €` }}</span>
                  </div>
                  <div class="current-balance-amount__bonuses">
                    <span>Bonuses:</span><span>{{ `${walletBonus} €` }}</span>
                  </div>
                </div>
              </div>
              <div class="wallet-current-balance__actions">
                <div>
                <button class="wallet-button deposit" @click="$router.push({path: '/profile/wallet/money-input'})">
                    Deposit
                  </button>
                <button  class="wallet-button withdrawal" @click="$router.push({path: '/profile/wallet/cash-withdrawal'})">
                    Withdrawal
                  </button>
                </div>
                <button class="promo-code-btn"
                          @click="openPromoPopup"
                  >
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.8765 14.5956C4.29586 14.0149 4.07425 13.1659 4.29705 12.3755L5.72875 7.29695C5.943 6.53696 6.53696 5.943 7.29695 5.72875L12.3755 4.29705C13.1659 4.07425 14.0149 4.29586 14.5956 4.8765L24.6125 14.8934C25.4986 15.7795 25.4986 17.2161 24.6125 18.1022L18.1022 24.6125C17.2161 25.4986 15.7795 25.4986 14.8934 24.6125L4.8765 14.5956Z" stroke-width="1.6"/>
                      <circle cx="11.6868" cy="11.6851" r="2.38701" transform="rotate(-45 11.6868 11.6851)" stroke-width="1.6"/>
                    </svg>
                    Use Promo Code
                  </button>
              </div>
            </div>
            <div class="wallet-songs-value">
              <div class="wallet-songs-value__title">total value of songs</div>
              <div class="wallet-songs-value__body">
                <span>{{ `${walletTotalSongAmount} ${currencyName}` }}</span> = <span>{{ `${walletTotalCost} €` }}</span>
              </div>
            </div>
            <div class="wallet-table">
              <div class="wallet-type-header">
                <div class="wallet-type-btn-wrap">
                  <div class="wallet-type-btn-item__wrap">
                  <button class="wallet-type-btn-item"
                          @click="toWalletTab"
                          :style="{background: walletTab === 'wallets' ? '#F5F5F5' : '#FFFFFF',
                                   padding: walletTab === 'wallets' ? '25px 45px 14px' : '14px 17px'
                        }"
                  >Wallets</button>
                  </div>
                  <div class="wallet-type-btn-item__wrap">
                  <button class="wallet-type-btn-item"
                          @click="toFinOpsTab"
                          :style="{background: walletTab === 'fin-ops' ? '#F5F5F5' : '#FFFFFF',
                                   padding: walletTab === 'fin-ops' ? '25px 14px' : '14px 17px'
                        }"
                  >{{ user.userType === 'SERVICE_PROVIDER' ? 'Provider' : 'Financial Operations' }}</button>
                  </div>
                </div>
              </div>
              <div class="wallet-table-body-wrap desktop">
                <div class="wallet-section"
                     v-if="walletTab === 'wallets'"
                >
                  <div class="table-wrap">
                    <table class="table orders-table">
                      <thead>
                      <tr>
                        <th class="left" colspan="2">{{ currencyName }}</th>
                        <th class="left" colspan="4" v-if="updCheck">Music composition</th>
                        <th class="left" colspan="2">Amount</th>
                        <th class="left" colspan="2">Price per {{ currencyName }}</th>
                        <th class="left" colspan="2">Total value of {{ currencyName }}</th>
                        <th class="left" colspan="1"></th>
                      </tr>
                      </thead>
                      <vuescroll :ops="ops" ref="vs">
                        <tbody>
                        <WalletPmList
                            v-for="(wallet, index) in walletMoney"
                            :key="index"
                            :wallet="wallet"
                            :updCheck="updCheck"
                        />
                        </tbody>
                      </vuescroll>
                      <tfoot>
                      <tr class="table-footer">
                        <td class="left" :colspan="updCheck ? 6 : 2">
                          <div class="button-wrap" v-if="walletSongs.length > 10">
                            <button type="button"
                                    class="show-all-items"
                                    v-if="showSongsFlag"
                                    @click="hidePm"
                            >
                              <span>All songs</span>
                              <svg :style="{transform: showSongsFlag ? 'rotate(180deg)' : 'rotate(0deg)'}"
                                   width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.14062 0.567505L4.24333 3.4326L7.34604 0.567505" stroke="#FF0090"
                                      stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </button>

                            <button type="button"
                                    class="show-all-items"
                                    v-else
                                    @click="showAllPm"
                            >
                              <span>All songs</span>
                              <svg :style="{transform: showSongsFlag ? 'rotate(180deg)' : 'rotate(0deg)'}"
                                   width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.14062 0.567505L4.24333 3.4326L7.34604 0.567505" stroke="#FF0090"
                                      stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </button>
                          </div>
                        </td>
                        <td colspan="4" class="left">
                          <div class="wallet-total-cost-title">Total</div>
                        </td>
                        <td colspan="2" class="left">
                          <div class="wallet-total-cost-value" v-text="'€' + walletSongsTotal"></div>
                        </td>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div class="wallet-section"
                     v-if="walletTab === 'fin-ops'"
                >

                  <div id="profile-financial-operations"
                       class="table-wrap"
                       v-if="operationsFlag"
                  >
                    <table class="table orders-table service-provider" v-if="user.userType === 'SERVICE_PROVIDER'">
                      <thead>
                      <tr>
                        <th class="left">Artist name</th>
                        <th class="left">Type of tariff</th>
                        <th class="left">Price</th>
                      </tr>
                      </thead>
                      <vuescroll :ops="ops" ref="vs">
                        <tbody>
                        <ProviderList
                            v-for="(item, index) in operations"
                            :key="index"
                            :item="item"
                        />
                        </tbody>
                      </vuescroll>
                    </table>

                    <table class="table orders-table" v-else>
                      <thead>
                      <tr>
                        <th class="left">Date</th>
                        <th class="left">Name</th>
                        <th class="left">Amount</th>
                        <th class="left">Event type</th>
                      </tr>
                      </thead>
                      <vuescroll
                          :ops="ops"
                          ref="vs"
                      >
                        <FinancialOperationsTemplate :operations="operations"/>
                      </vuescroll>
                    </table>
                  </div>
                  <div class="profile-content empty-response" v-else>
                    <span>You don't have any financial Operations yet.</span>
                  </div>
                  <b-pagination-nav v-if="operations.length !== 0"
                                    class="pagination"
                                    v-model="page"
                                    :total-rows="count"
                                    :per-page="pageSize"
                                    :link-gen="linkGen"
                                    :number-of-pages="count"
                                    prev-text="«"
                                    next-text="»"
                                    first-number
                                    last-number
                                    use-router
                  ></b-pagination-nav>

                </div>
              </div>
              <div class="wallet-table-body-wrap mobile">
                <div class="wallet-table-mobile-title" v-if="walletTab === 'wallets'">
                  <div class="mobile-title__song">Song</div><div class="mobile-title__value">Value</div>
                </div>
                <div class="wallet-table-mobile-title" v-if="walletTab === 'fin-ops'">
                  <div class="mobile-title__song fin-ops">Amount</div><div class="mobile-title__value fin-ops">Details</div>
                </div>
                <div class="wallet-table-mobile-body" v-if="walletTab === 'wallets'">
                  <WalletPmListMobile
                      v-for="(wallet, index) in walletMoney"
                      :key="index"
                      :wallet="wallet"
                  />
                </div>
                <div class="wallet-table-mobile-body" style="overflow-x: hidden" v-if="walletTab === 'fin-ops'">
                  <WalletFinOpsMobile
                      v-for="(operation, index) in operations"
                      :key="index"
                      :operation="operation"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="preloader-wrapper profile-preloader" v-else>
            <v-progress-circular
                :size="75"
                :width="8"
                color="#FF0090"
                indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </div>
      <ServerErrorPopup ref="serverErrorPopup" :errorMessage="error.errorMessage"/>
      <PromoCodePopup ref="promoCodePopup" @getPromoCode="setPromoCode" @updateChanges="updatePromoCode" :reset="reset"/>
      <SuccessPopup ref="successPopup" :errorMessage="successMessage" />
    </main>
    <Footer/>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import WalletPmList from './WalletPmList';
import WalletPmListMobile from "./WalletPmListMobile";
import WalletFinOpsMobile from "./WalletFinOpsMobile";
import ProviderList from "./ProviderList";
import FinancialOperationsTemplate from "./FinancialOperationsTemplate";
import { mapGetters } from 'vuex';
import vuescroll from "vuescroll";
import ServerErrorPopup from "../popups/ServerErrorPopup";
import PromoCodePopup from "../popups/PromoCodePopup";
import SuccessPopup from "../popups/SuccessPopup";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";

export default {
  name: "Wallet",
  data() {
    return {
      updCheck: false,
      currencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT,
      loadFlag: false,
      tableLoadFlag: false,
      walletCurrency: null,
      walletBonus: {},
      walletSongs: [],
      operations: [],
      walletSongsTotal: null,
      walletMoney: null,
      walletTotalAmount: null,
      walletTotalSongAmount: null,
      walletTotalCost: null,
      showSongsFlag: false,
      operationsFlag: false,
      walletTab: 'wallets',
      page: 1,
      count: 0,
      pageSize: 20,
      error: {
        errorMessage: '',
      },
      formData: new FormData(),
      reset: false,
      successMessage: '',
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          background: '#FB3099',
          size: '3px'
        }
      },
    }
  },
  mixins: [numberFormattingDecimal],
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
  },
  methods: {
    toWalletTab() {
      this.walletTab = 'wallets'
    },
    toFinOpsTab() {
      this.walletTab = 'fin-ops'
    },
    dataParse(data, tabName) {
      return data.filter(i => i.currencyFullName === tabName)
    },
    goTo(refName) {
      let element = this.$refs[refName]
      element.scrollIntoView({behavior: 'smooth'})
    },
    hidePm() {
      this.showSongsFlag = false
      this.walletMoney = this.walletSongs.slice(0, 10)
    },
    showAllPm() {
      this.showSongsFlag = true
      this.walletMoney = this.walletSongs.slice(0, 1000)
    },
    linkGen(pageNum) {
      return {
        path: '/profile/wallet',
        query: {page: pageNum}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = 20;
      }

      return params;
    },
    dataRequest() {
      if (this.walletTab === 'wallets') {
        this.$load(async () => {
          this.loadFlag = false
          let response = (await this.$api.wallet.get())
          let wallets = response.data.body
          this.walletTotalAmount = this.numberFormattingDecimal(wallets.totalAmount)
          this.walletTotalSongAmount = this.numberFormattingDecimal(wallets.songWallet.totalSongAmount)
          this.walletTotalCost = this.numberFormattingDecimal(wallets.songWallet.totalCost)
          this.walletCurrency = this.numberFormattingDecimal(wallets.mainWallet.amount)
          this.walletSongs = wallets.songWallet.songCurrencyWallets.content
          this.walletSongsTotal = this.numberFormattingDecimal(wallets.songWallet.totalCost)
          this.walletMoney = this.walletSongs.slice(0, 10)

          if (this.walletMoney) {
            const keyNameList = ['authorPublicName', 'title', 'albumCover']
            let allCheck

            const check = function (obj) {
              const objKeys = Object.getOwnPropertyNames(obj)
              Array.prototype.diff = function (a) {
                return this.filter((i) => a.indexOf(i) >= 0)
              }

              const result = objKeys.diff(keyNameList)
              allCheck = result.length === keyNameList.length
              return allCheck
            }

            this.walletMoney.forEach((el) => check(el))
            this.updCheck = allCheck
          }

          this.walletBonus = this.numberFormattingDecimal(wallets.promotionWallet.amount)
          // this.walletCurrency = wallets.filter(i => i.currencyFullName === 'EUR')
          // this.walletSongs = wallets.filter(i => i.currencyFullName !== 'EUR')
          // this.walletMoney = this.walletSongs.slice(0, 10)
          this.loadFlag = true
        }, error => {
          this.error.serverErrorerrorMessage = error.data.errors[0].message
          this.$refs.serverErrorPopup.openPopups()
        })
      } else {
        const params = this.getRequestParams(
            this.page,
            this.pageSize
        );

        this.$load(async () => {
          this.loadFlag = false
          const res = (await this.$api.financialOperations.getFinancialOperations(params)).data.body
          this.operations = res.elements
          this.count = res.totalPages
          this.loadFlag = true
        })
      }
    },
    setFormData() {
      this.$load(async () => {
        let res = await this.$api.promotion.setData(this.formData)
        this.reset = true
        if (res.status) {
          this.successMessage = 'Thanks for using the promo code, your Wallet has been credited with X Euro!'
          this.$refs.successPopup.openPopups()
        }
      }, error => {
        this.error.errorMessage = error.data.errors[0].message
        this.$refs.serverErrorPopup.openPopups()
        this.reset = true
      })
    },
    openPromoPopup() {
      this.$refs.promoCodePopup.openPopup()
    },
    setPromoCode() {
      this.setFormData()
    },
    updatePromoCode(event) {
      let dataKey = event.target.name
      let dataVal = event.target.value
      this.formData.set(dataKey, dataVal)
    },
  },
  watch: {
    walletTab() {
      this.dataRequest()
    },
    'operations'() {
      this.operationsFlag = this.operations.length > 0;
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = newVal
          this.dataRequest()
          this.goTo('top')
        } else {
          this.page = 1
          this.dataRequest()
        }
      }
    },
  },
  mounted() {
    document.title = 'Wallet'

    this.page = this.$route.query.page || 1
    this.dataRequest()
    this.goTo('top')
  },
  components: {
    Footer,
    Header,
    Sidebar,
    vuescroll,
    ServerErrorPopup,
    PromoCodePopup,
    WalletPmList,
    WalletPmListMobile,
    WalletFinOpsMobile,
    ProviderList,
    FinancialOperationsTemplate,
    SuccessPopup,
  }
}
</script>

<style scoped>

.wallet-header {
  margin-bottom: 40px;
}

.wallet-type-btn-wrap {
  display: flex;
  max-width: 450px;
}
.wallet-type-btn-item__wrap {
  position: relative;
  width: calc(50% - 4px);
  display: flex;
  align-items: flex-end;
  margin-right: 2px;
}

.wallet-type-btn-item {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  color: #292929;
  background: #FFF5FB;
  border-radius: 10px 10px 0 0;
  transition: .5s ease;
  cursor: pointer;
}

.wallet-table-body-wrap.mobile {
  display: none;
  width: 100%;
  border: 1px solid #DEDEDE;
  border-radius: 0 0 5px 5px;
  padding: 0 8px;
}

.wallet-table-mobile-title {
  display: flex;
  padding: 20px 12px 14px;
  background: #F5F5F5;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #272727;
  margin-top: 15px;
}

.mobile-title__song {
  width: 65%;
}

.mobile-title__value {
  width: 35%;
}

.mobile-title__song.fin-ops, .mobile-title__value.fin-ops {
  width: 50%;
}

.table {
  margin-bottom: 0;
}
.table-wrap {
  margin-bottom: 40px;
  border: none;
}
.table-wrap .table tbody {
  max-height: 483px;
}
.wallet-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.wallet-button {
  padding: 9px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  transition: all 0.3s ease-in-out;
}
.wallet-button.deposit {
  background: #FF0090;
  border-radius: 5px;
  color: #FFFFFF;
  border: 1px solid #FF0090;
  margin-right: 20px;
}
.wallet-button.withdrawal {
  border: 1px solid #FF0090;
  border-radius: 5px;
  color: #FF0090;
}

.wallet-button.deposit:hover {
  color: #FF0090;
  background: transparent;
}

.wallet-button.withdrawal:hover {
  color: #FFFFFF;
  background: #FF0090;
}

.show-all-items {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #FF0090;
  transition: .4s ease;
}

.show-all-items span {
  margin-right: 5px;
}

.show-all-items svg {
  transition: .4s ease;
}

table.service-provider thead tr th:first-child {
  width: 60%;
}

.promo-code-btn {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FF0090;
  transition: .5s ease;
  margin-right: 20px;
}

.promo-code-btn svg {
  stroke: #FF0090;
  margin-right: 9px;
  transition: .5s ease;
}

.promo-code-btn:hover {
  color: #464646;
}

.promo-code-btn:hover svg {
  stroke: #464646;
}

.wallet-total-cost-value,
.wallet-total-cost-title {
  font-weight: 600;
}

.wallet-current-balance {
  display: flex;
  margin-bottom: 30px;
}

.wallet-current-balance__amount {
  margin-right: 60px;
}

.current-balance-amount__title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 5.4px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 15px;
}

.current-balance-amount__amount {
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 100%;
  color: #000000;
  margin-bottom: 15px;
}

.current-balance-amount__funds, .current-balance-amount__bonuses {
  display: flex;
  justify-content: space-between;
}

.wallet-current-balance__actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wallet-songs-value {
  margin-bottom: 30px;
}

.wallet-songs-value__title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 5.4px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 15px;
}

.wallet-songs-value__body {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 100%;
  color: #000000;
}

.active-line {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 32px;
  height: 3px;
  background: #FF0090;
}

.orders-table tfoot {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.table thead tr th {
  border: none;
}


@media only screen and (max-width: 1100px) {
  .promo-code-btn {
    order: 1;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 850px) {
  .wallet-button {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .table tbody tr:last-child {
    border-bottom: 1px solid #DEDEDE;
  }

  table.service-provider thead tr th:first-child {
    width: 100%;
  }

  .table-wrap .table tbody {
    margin-bottom: 10px;
  }

  /*.wallet-table-body-wrap.mobile {*/
  /*  display: block;*/
  /*}*/

  /*.wallet-table-body-wrap.desktop {*/
  /*  display: none;*/
  /*}*/
}

@media only screen and (max-width: 700px) {
  .wallet-current-balance {
    flex-direction: column;
  }

  .wallet-current-balance__amount {
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  .wallet-type-btn-item {
    font-size: 16px;
  }
}

@media only screen and (max-width: 500px) {
  .wallet-type-btn-item {
    padding: 8px;
    font-size: 15px;
  }

  .wallet-header {
    flex-wrap: wrap;
  }

  .wallet-header h1 {
    width: 100%;
  }

  .wallet-type-btn-wrap {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 375px) {
  .wallet-type-btn-item {
    font-size: 13px;
  }

  .current-balance-amount__funds-and-bonuses {
    padding-right: 10px;
  }
}

/* hovers only for desktop */

@media only screen and (min-width: 992px) {
  .show-all-items:hover {
    opacity: 0.6;
  }
}

</style>