<template>
  <main id="root">
    <div style="width: 100%; text-align: center">
      <div v-html="initialSaleContract" style="font-size: 15px;" class="document-wrapper" v-if="initialSaleContract"></div>
      <div v-html="adminPlatformContract" style="font-size: 15px;" class="document-wrapper" v-if="adminPlatformContract"></div>
    </div>
    <ServerErrorPopup ref="ServerErrorPopup" :errorMessage="error.message"/>
  </main>
</template>

<script>
import ServerErrorPopup from "@/components/popups/ServerErrorPopup";

export default {
  name: "MobApplicationContractPage",
  components: {ServerErrorPopup},
  data() {
    return {
      error: {
        message: null,
        status: null,
      },
      initialSaleContract: null,
      adminPlatformContract: null,
      ieoAdminId: 0
    }
  },
  methods: {
    initialSaleContractRequest() {
      this.$load(async () => {
        const params = {
          ieoUuid: this.$route.params.id
        }
        let res = await this.$api.contract.getInitialContract(params)
        this.initialSaleContract = atob(res.data.body.htmlTemplate)
        setTimeout(() => {
          this.changeStylesForPages()
        }, 500)
      })
    },
    adminPlatformContractRequest() {
      this.$load(async () => {
        const params = {
          ieoUuid: this.$route.params.id,
          songAdminId: this.$route.query.adminId ? Number(this.$route.query.adminId) : this.ieoAdminId
        }
        let res = await this.$api.contract.getAdminPlatformContract(params)
        this.adminPlatformContract = atob(res.data.body.htmlTemplate)
        setTimeout(() => {
          this.changeStylesForPages()
        }, 500)
      })
    },
    changeStylesForPages() {
      const pages = document.getElementsByClassName('format-a4');
      const border = document.getElementsByClassName('pink-border')
      border.forEach((item, index) => {
        console.log(item)
        // item.style.width = '100%'
        item.style.display = 'none'
      })
      pages.forEach((page) => {
        page.style.height = 'unset'
        page.style.maxHeight = 'unset'
        page.style.minHeight = '280mm'
        page.style.paddingBottom = '30px'
        const div = document.createElement('div')
        div.style.position = 'absolute'
        div.style.bottom = '0'
        div.style.height = '2.11mm'
        div.style.width = '100%'
        div.style.background = '#FF0090'
        page.appendChild(div)
      })
    },
  },
  created() {
    const {type} = this.$route.query
    type === 'initialSale'? this.initialSaleContractRequest() : this.adminPlatformContractRequest()
  }
}
</script>

<style scoped>
.format-a4 {
  padding: 10px;
}

.document-wrapper {

}
</style>



