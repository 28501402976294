import Router from "vue-router";
import { alert } from "./alert.module";
import { authentication } from "@/store/authentication.module";
import { users } from "./users.module";
import Home from "../pages/Home.vue";
import NewsList from "../pages/NewsList.vue";
import NFTMarketplace from "../pages/NFTMarketplace.vue";
import ArtistsList from "../pages/ArtistsList.vue";
import Team from "../pages/Team.vue";
import Profile from "../pages/Profile";
import MySong from "../components/profile/MySong";
import CreateSong from "../components/profile/CreateSong";
import Login from "../pages/Login";
import Ieos from "../components/profile/Ieos";
import Invite from "../components/profile/Invite";
import Favorite from "../components/profile/Favorites";
import Notifications from "../components/profile/Notifications";
import TradingHistory from "../components/profile/TradingHistory";
import Wallet from "../components/profile/Wallet";
import MyOrders from "../components/profile/MyOrders";
import InitialSaleHistory from "../components/profile/InitialSaleHistory";
import IeoCreating from "../components/profile/IeoCreating";
import IeoCreatingThirdStepRedirect from "../components/profile/IeoCreatingThirdStepRedirect";
import ArtistProfile from "../components/artist/ArtistProfile";
import Song from "../components/song/Song";
import NewsPage from "../components/news/NewsPage";
import Policy from "../components/static/Policy";
import TermsAndConditions from "../components/static/TermsAndConditions";
import ContactUs from "../components/static/ContactUs";
import FAQ from "../components/static/FAQ";
import Error404 from "../components/errors/Error-404";
import PrimaryMarket from "../pages/PrimaryMarket";
import Register from "../pages/Register";
import TestComponent from "../components/flip-clock/TestComponent";
import Trading from "@/components/trading/Trading";
import HowItWorks from "../components/static/HowItWorks";
import KYC from "../pages/KYC";
import PasswordReset from "../pages/PasswordReset";
import ResetPasswordConfirm from "../components/static/ResetPasswordConfirm";
import WalletMoneyInput from "../components/profile/WalletMoneyInput";
import WalletCashWithdrawal from "../components/profile/WalletCashWithdrawal";
import PaymentServiceProviderTermsAndConditions from "../components/static/PaymentServiceProviderTermsAndConditions";
import Agreements from "../components/agreements/Agreements";
import ArtistDjookyAwardsProfile from "../components/artist/ArtistDjookyAwardsProfile";
import CardPaymentHandler from "@/components/static/CardPaymentHandler";
import CardDepositHandler from "@/components/static/CardDepositHandler";
import InternalPaymentHandler from "@/components/static/InternalPaymentHandler";
import CompanyProfile from "@/components/company/CompanyProfile";
import CompanyAssociatedArtists from "@/components/company/CompanyAssociatedArtists";
import CompanyCreateAssociatedArtist from "@/components/company/CompanyCreateAssociatedArtist";
import CompanyEditAssociatedArtist from "@/components/company/CompanyEditAssociatedArtist";
import SecondaryMarket from "@/pages/SecondaryMarket";
import RoyaltyPage from "@/components/profile/royalty/RoyaltyPage";
import RoyaltyReport from "@/components/profile/royalty/components/RoyaltyReport";
import OperationStatusComponent from "@/components/static/OperationStatusComponent";
import RoyaltyReportStatus from "@/pages/RoyaltyReportStatus";
import MobApplicationContractPage from "@/pages/MobApplicationContractPage";
import MobileApplication from "@/pages/MobileApplication.vue";
import MobileKYC from "@/components/mobile-application/KYC.vue"
import MobileFAQ from "@/components/mobile-application/FAQ.vue"
import RedirectPage from "@/pages/RedirectPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/password/reset",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/password/reset/confirm",
    name: "PasswordResetConfirm",
    component: ResetPasswordConfirm,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/trading/:id?",
    name: "Trading",
    component: Trading,
  },
  {
    path: "/market",
    name: "SecondaryMarket",
    component: SecondaryMarket,
  },
  {
    path: "/how-it-works",
    name: "HowItWorks",
    component: HowItWorks,
  },
  {
    path: "/news",
    name: "NewsList",
    component: NewsList,
  },
  {
    path: "/nft-marketplace",
    name: "NFTMarketplace",
    component: NFTMarketplace,
  },
  {
    path: "/article/:id?",
    name: "NewsPage",
    component: NewsPage,
  },
  {
    path: "/artists",
    name: "ArtistsList",
    component: ArtistsList,
  },
  {
    path: "/auction",
    name: "PrimaryMarket",
    component: PrimaryMarket,
  },
  {
    path: "/royalty-report-status",
    name: "RoyaltyReportStatus",
    component: RoyaltyReportStatus,
  },
  {
    path: "/internal-payment-handler/:id?",
    name: "InternalPaymentHandler",
    component: InternalPaymentHandler,
    meta: { requiresAuth: true },
  },
  {
    path: "/card-deposit-handler",
    name: "CardDepositHandler",
    component: CardDepositHandler,
    meta: { requiresAuth: true },
  },
  {
    path: "/card-payment-handler/:id?",
    name: "CardPaymentHandler",
    component: CardPaymentHandler,
    meta: { requiresAuth: true },
  },
  {
    path: "",
    name: "OperationStatusComponent",
    component: OperationStatusComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/artist/:id?",
    name: "ArtistProfile",
    component: ArtistProfile,
    meta: { requiresAuth: false },
  },
  {
    path: "/artist-djk-awards/:id?",
    name: "ArtistDjookyAwardsProfile",
    component: ArtistDjookyAwardsProfile,
    meta: { requiresAuth: false },
  },
  {
    path: "/song/:id?",
    name: "Song",
    component: Song,
  },
  {
    path: "/company/:id?",
    name: "CompanyProfile",
    component: CompanyProfile,
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/profile/my-songs",
    name: "MySong",
    component: MySong,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/create-song",
    name: "CreateSong",
    component: CreateSong,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/ieos",
    name: "Ieos",
    component: Ieos,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/invite",
    name: "Invite",
    component: Invite,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/offers",
    name: "NFTMarketplace",
    component: NFTMarketplace,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/nfts",
    name: "NFTMarketplace",
    component: NFTMarketplace,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/nft/:tokenId",
    name: "NFTMarketplaceToken",
    component: NFTMarketplace,
    meta: { requiresAuth: true },
  },
  {
    path: "/explorer/:tokenId",
    name: "NFTMarketplaceExplorer",
    component: NFTMarketplace,
    meta: { requiresAuth: false },
  },
  {
    path: "/profile/licenses",
    name: "NFTMarketplaceLicenses",
    component: NFTMarketplace,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/license/:licenseId",
    name: "NFTMarketplaceLicense",
    component: NFTMarketplace,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/my-license/:licenseId",
    name: "NFTMarketplaceMyLicense",
    component: NFTMarketplace,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/create-offer/:tokenId",
    name: "NFTMarketplaceCreateOffer",
    component: NFTMarketplace,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/license-preview/:tokenId",
    name: "NFTMarketplacePreviewOffer",
    component: NFTMarketplace,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/favorite",
    name: "Favorite",
    component: Favorite,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/notifications",
    name: "Notifications",
    component: Notifications,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/trading-history",
    name: "TradingHistory",
    component: TradingHistory,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/wallet",
    name: "Wallet",
    component: Wallet,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/wallet/money-input",
    name: "WalletMoneyInput",
    component: WalletMoneyInput,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/wallet/cash-withdrawal",
    name: "WalletCashWithdrawal",
    component: WalletCashWithdrawal,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/my-orders",
    name: "MyOrders",
    component: MyOrders,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/initial-sale-history",
    name: "InitialSaleHistory",
    component: InitialSaleHistory,
    meta: { requiresAuth: true },
  },
  // {
  //     path: "/profile/provider",
  //     name: "Provider",
  //     component: Provider,
  //     meta: {
  //         requiresAuth: true
  //     }
  // },
  {
    path: "/profile/kyc",
    name: "KYC",
    component: KYC,
    meta: { requiresAuth: true },
  },
  {
    path: `/profile/ieo-creating/:id?`,
    name: "IeoCreating",
    component: IeoCreating,
  },
  {
    path: `/profile/agreements`,
    name: "Agreements",
    component: Agreements,
  },
  {
    path: `/profile/ieo-creating/:id?/step/final`,
    name: "IeoCreatingThirdStepRedirect",
    component: IeoCreatingThirdStepRedirect,
  },
  {
    path: `/policy/:key?`,
    name: "Policy",
    component: Policy,
  },
  {
    path: `/terms-conditions/:key?`,
    name: "TermsAndConditions",
    component: TermsAndConditions,
  },
  {
    path: `/payment-service-provider-terms-conditions`,
    name: "PaymentServiceProviderTermsAndConditions",
    component: PaymentServiceProviderTermsAndConditions,
  },
  {
    path: `/contact/`,
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: `/faq/search/`,
    name: "FAQ",
    component: FAQ,
  },
  {
    path: `/error/`,
    name: "Error-404",
    component: Error404,
  },
  {
    path: "/test",
    name: "Test",
    component: TestComponent,
  },
  {
    path: "/profile/associated-artists",
    name: "CompanyAssociatedArtists",
    component: CompanyAssociatedArtists,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/associated-artists/create-artist",
    name: "CompanyCreateAssociatedArtist",
    component: CompanyCreateAssociatedArtist,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/associated-artists/edit-artist/:id?",
    name: "CompanyEditAssociatedArtist",
    component: CompanyEditAssociatedArtist,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile/royalty",
    name: "RoyaltyPage",
    component: RoyaltyPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: "report",
        name: "RoyaltyReport",
        component: RoyaltyReport,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: `/application/contracts/:id?`,
    name: "Application Contracts",
    component: MobApplicationContractPage
  },
  {
    path: "/mob-app",
    name: "RoyaltyPage",
    component: MobileApplication,
    meta: { requiresAuth: false },
  },
  {
    path: "/page/redirect",
    name: "RedirectPage",
    component: RedirectPage,
    meta: { requiresAuth: false },
  },
];

export const router = new Router({
  mode: "history",
  routes,
  modules: {
    alert,
    status,
    authentication,
    users,
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("isLogged") == null) {
      return next("/login");
    } else {
      return next();
    }
  } else {
    return next();
  }
});

router.afterEach((to, from) => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
})

export default router;
