<template>
  <div>
    <apexchart type="line" :options="options" :series="series" v-if="flag"></apexchart>
  </div>
</template>

<script>


export default {
  name: "BidsChart",
  props: {
    ieoBidsData: Array
  },
  data() {
    return {
      setWidth: 415,
      options: {
        chart: {
          type: 'area',
          stacked: false,
          height: 270,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        stroke: {
          curve: 'straight'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: function (val, opts) {
            return val
          },
          textAnchor: 'middle',
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontFamily: 'Gilroy',
            fontWeight: 'normal',
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderRadius: 5,
            borderWidth: 3,
            borderColor: '#fff',
            opacity: 1,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45
            }
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        plotOptions: {
          line: {
            curve: 'smooth',
          }
        },
        markers: {
          size: 0,
          style: 'full',
        },
        colors: ['#FB018E'],
        title: {
          // text: 'Stock Price Movement',
          align: 'left'
        },
        fill: {
          type: 'gradient',
          gradient: {
            enabled: true,
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0,
            opacityTo: 0,
            stops: [0, 70, 80, 100]
          },
        },
        yaxis: {
          labels: {
            show: false,
            formatter: function (val) {
              return val.toFixed(2)
            },
          },
          title: {
            // text: 'Price'
          },
        },
        xaxis: {
          type: 'datetime',
          crosshairs: {
            width: 1
          },

        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: true,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: false,
          theme: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          onDatasetHover: {
            highlightDataSeries: false,
          },
          x: {
            show: true,
            format: 'dd MMM',
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
          z: {
            formatter: undefined,
            title: 'Size: '
          },
          marker: {
            show: false,
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      series: [{
        name: '€',
        data: []
      }],
      flag: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.flag = true
    }, 500)
    for (const i of this.ieoBidsData) {
      i.timeBid = i.timeBid.split('-').join('/')
      let innerArr = [i.timeBid, i.bidPerIpr]
      this.series[0].data.push(innerArr)
    }
    window.addEventListener('resize', () => {
      if(innerWidth > 1200) {
        this.setWidth = 685
      } else if (innerWidth <= 779) {
        this.setWidth = 500
      } else if (innerWidth <= 500) {
        this.setWidth = innerWidth - 40
      }
    })
  }
}
</script>

<style scoped>

</style>