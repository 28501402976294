<template>
  <div class="document-sign-wrapper" ref="top">
    <div class="document-sign__step-wrapper">
      <div class="steps step_two_point_one" :style="firstStepFill">2.1</div>
      <div class="steps step_two_point_two" :style="secondStepFill">2.2</div>
    </div>
    <div class="document-sign-text" v-if="!initialSaleSigned">
      <div class="document-sign__artist">
        <vuescroll
            :ops="ops"
            ref="vs"
            @handle-scroll="handleScrollInitialSale"
        >
          <div style="width: 100%; text-align: center">
<!--            <iframe :src="initialSaleContract" v-if="initialSaleContract" ref="frame" height="3300" class="iframe"/>-->
            <div v-html="initialSaleContract" style="font-size: 15px;" class="document-wrapper"></div>
          </div>
        </vuescroll>
      </div>
      <div class="button-wrapper">
        <button type="button" id="sellButtonStopLimitSecondStep"
                class="btn btn-hover"
                @click="initialSaleAgreed"
                :disabled="!checkBox"
                :class="{disabled: !checkBox}"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
    <div class="document-sign-text2" v-else>
      <vuescroll
          :ops="ops"
          ref="vs2"
          @handle-scroll="handleScrollAdmin"
      >
        <div style="width: 100%; text-align: center">
<!--          <iframe :src="adminPlatformContract" v-if="adminPlatformContract" class="iframe" height="2475px" ref="frame"/>-->
          <div v-html="adminPlatformContract" style="font-size: 15px;" class="document-wrapper"></div>
        </div>
      </vuescroll>
      <div class="button-wrapper">
        <button type="button" id="sellButtonStopLimitSecondStep2"
                class="btn btn-hover"
                @click="adminAgreed"
                :disabled="!checkBox2"
                :class="{disabled: !checkBox2}"
        >
          {{ buttonText2 }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import vuescroll from 'vuescroll';
import {mapGetters} from "vuex"


export default {
  name: "IeoCreatingDocumentSign",
  components: {
    vuescroll
  },
  props: {
    ieoAdminId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      checkBox: false,
      checkBox2: false,
      scrollFlag: true,
      buttonText: 'Scroll down to accept',
      buttonText2: 'Scroll down to accept',
      initialSaleContract: null,
      adminPlatformContract: null,
      initialSaleSigned: false,
      adminSigned: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          background: '#FB3099',
          size: '3px'
        }
      },

    };
  },
  methods: {
    initialSaleAgreed() {
      // this.$emit('documentSigned', 'CARD')
      this.$load(async () => {
        const params = {
          ieoUuid: this.$route.params.id
        }
        await this.$api.contract.initialContract(params)
      })
      this.initialSaleSigned = true
      this.$refs["vs"].scrollTo(
          {
            y: 0
          },
          0,
          "easeInQuad"
      );
      this.goTo('top')
      setTimeout(() => {
        this.changeStylesForPages()
      }, 500)
    },
    adminAgreed() {
      this.$emit('documentSigned', 'CARD')
      this.adminSigned = true
    },
    handleScrollInitialSale(vertical, horizontal, nativeEvent) {
      if (vertical.process >= 0.98) {
        setTimeout(() => {
          this.buttonText = 'I have read and agree to the Agreements'
          this.checkBox = true
        }, 10)

      }
    },
    changeStylesForPages() {
      const pages = document.getElementsByClassName('format-a4');
      const border = document.getElementsByClassName('pink-border')
      border.forEach((item, index) => {
        console.log(item)
        // item.style.width = '100%'
        item.style.display = 'none'
      })
      pages.forEach((page) => {
        page.style.maxWidth = '200mm'
        page.style.height = 'unset'
        page.style.maxHeight = 'unset'
        page.style.minHeight = '280mm'
        page.style.paddingBottom = '30px'
        const div = document.createElement('div')
        div.style.position = 'absolute'
        div.style.bottom = '0'
        div.style.height = '2.11mm'
        div.style.width = '100%'
        div.style.background = '#FF0090'
        page.appendChild(div)
      })
    },
    handleScrollAdmin(vertical, horizontal, nativeEvent) {
      if (this.initialSaleSigned && vertical.process >= 0.98) {
        this.buttonText2 = 'I have read and agree to the Administration Agreements'
        this.checkBox2 = true
      }
    },
    goTo(refName) {
      let element = this.$refs[refName]
      element.scrollIntoView({behavior: 'smooth'})
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser"
    }),
    firstStepFill() {
      return this.initialSaleSigned ?
          {
            background: '#FF0090',
          } : {
            background: 'rgba(234, 234, 234, 0.6)',
          }
    },
    secondStepFill() {
      return this.adminSigned ?
          {
            background: '#FF0090',
          } : {
            background: 'rgba(234, 234, 234, 0.6)',
          }
    }
  },
  created() {
    this.$load(async () => {
      const params = {
        ieoUuid: this.$route.params.id
      }
      let res = await this.$api.contract.getInitialContract(params)
      this.initialSaleContract = atob(res.data.body.htmlTemplate)
      console.log(this.initialSaleContract)

    })
    this.$load(async () => {
      const params = {
        ieoUuid: this.$route.params.id,
        songAdminId: this.$route.query.adminId ? Number(this.$route.query.adminId) : this.ieoAdminId
      }
      let res = await this.$api.contract.getAdminPlatformContract(params)
      this.adminPlatformContract = atob(res.data.body.htmlTemplate)
      console.log(this.adminPlatformContract)
    })
  },
  mounted() {
    this.goTo('top')
    setTimeout(() => {
     this.changeStylesForPages()
    }, 500)


  },
  beforeDestroy() {
    // this.goTo('top-title')
  },
  destroyed() {
    this.checkBox = true
    this.buttonText = 'Scroll down to accept'
  },

}
</script>

<style scoped>
ol {
  list-style: none;
}

.iframe {
  width: 600px;
}

.document-sign__artist, .document-sign__company, .document-sign-text2 {
  height: 520px;
  border: 1px solid #EAEAEA;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #A6A6A6;
  margin-bottom: 40px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.popup-agreement-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #131415;
}

.agreement-contract {
  border: 1px solid #EAEAEA;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 300px;
  margin-bottom: 30px;
}

.agreement-contract h2 {
  margin: 15px 0 0 0;
  padding: 0;
  flex: 20px;
  line-height: 20px;
}

.agreement-contract .agreement-artist-field, .agreement-investor-field {
  width: 50%;
}

.agreement-contract .agreement-artist-field {
  border-right: 1px solid #EAEAEA;
}

.agreement-artist-field h2, .agreement-investor-field h2 {
  text-align: center;
}

.agreement-contract .bold-line {
  display: block;
  height: 1px;
  background-color: #000;
  margin: 100px auto 0;
  width: 80%;
}

.agreement-contract .thin-line {
  margin-top: 120px;
  display: block;
  height: 1px;
  background-color: #EAEAEA;
}

.popup-content-text li {
  list-style-type: none;
}

.agreement-contract-table {
  border: 2px solid #EAEAEA;
  padding: 0 5px;
}

.agreement-contract-table p {
  margin: 0 !important;
}

.static-page-text-wrap p, ul li, ol li {
  font-style: normal;
  font-weight: normal;
  color: #A6A6A6;
}

.btn.disabled, .btn.disabled:hover {
  background: #CECECE;
  border: 1px solid transparent;
  cursor: default;
  outline: none;
  box-shadow: none;
  color: #FFFFFF;
}

.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-weight: bold;
  color: white;
}

.document-sign__step-wrapper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}

.document-sign-wrapper ul li, .document-sign-wrapper ol li {
  color: #8C8C8C;
  list-style: unset;
  font-size: 15px;
}

.djooky-list {
  margin-left: 24px;
}

@media only screen and (max-width: 768px) {
  .document-wrapper {
    zoom: .6;
    -ms-zoom: .6;
  }
}



</style>