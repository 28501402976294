<template>
  <div class="my-song-list-item my-songs">
    <div class="pending-ieo-list-item-header">

      <div class="pending-ieo-label" v-if="song.totalPlanned && song.status !== 'FAILED'">
        <span class="pending-ieo-label-title">Total Planned:</span>
        <span class="pending-ieo-label-price" v-text="'€ ' + totalPlanedToFormat"></span>
      </div>

      <div class="edit-image-label-wrap" v-if="!favoritesFlag" @click.stop="changeImagePopup(song.songId)">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.4509 1.875L13.1253 0.549141C12.7591 0.183164 12.2789 0 11.7992 0C11.3196 0 10.8397 0.183164 10.4735 0.549141L2.4239 8.59863C2.05816 8.96484 0.937966 10.3812 0.937497 10.8609L0 15L4.13823 14.0625C4.13823 14.0625 6.03526 12.9418 6.40147 12.5756L14.4511 4.52637C15.183 3.79395 15.183 2.6066 14.4509 1.875ZM1.24277 13.7576L1.85156 11.0678C1.86023 11.0293 1.86667 10.99 1.87031 10.9515C1.87218 10.946 1.87722 10.9378 1.87945 10.9323L3.99936 13.0527C3.92846 13.0957 3.85698 13.1387 3.7869 13.1809L1.24277 13.7576ZM5.7403 11.9111C5.63412 12.0117 5.26698 12.2599 4.81838 12.5455L2.38956 10.1166C2.63859 9.77508 2.91597 9.43172 3.08671 9.26145L9.14786 3.20074L11.7992 5.85211L5.7403 11.9111ZM13.7881 3.86355L12.462 5.1893L9.81067 2.53793L11.1368 1.21219C11.3135 1.03465 11.5488 0.9375 11.7992 0.9375C12.0497 0.9375 12.2853 1.03547 12.4625 1.21219L13.7882 2.53793C13.9654 2.71465 14.0625 2.94996 14.0625 3.20074C14.0623 3.4507 13.9648 3.68684 13.7881 3.86355Z" fill="#313131"/>
        </svg>
        <span class="edit-image-label-title">Edit Image</span>
      </div>

      <img v-if="song.songAlbumUrlImage" :src="song.songAlbumUrlImage" alt="Author image"
           class="pending-ieo-list-item-image"
           onerror="this.onerror=null;this.src='https://static.djooky.fun/covers/covers/images_iOGJxAB.jpg';">
      <img v-else src="../../assets/img/default-song-image.jpg" alt="Author image"
           class="pending-ieo-list-item-image artist-list-item-image-default">
      <div class="pending-ieo-list-item__play-wrap">
        <div :class="togglePlayerFlag ?
                'hitparad_button-play hitparad_button-pause'
                : 'hitparad_button-play hitparad_button-play-active'" @click="toggleMusic">
          <div class="hitparad_button-play-progress">
            <canvas width="70" height="70"></canvas>
            <canvas width="70" height="70"></canvas>
          </div>
        </div>
      </div>
      <div class="audio-player-wrapper">
        <AudioPlayer
            :src="playerSrc"
            :togglePlayerFlag="togglePlayerFlag"
            :width="'100%'"
            v-if="playerSrc"
            :ref="`audioPlayer${identifier}${index}`"
            @audioPause="togglePlayerFlag = true"
            :identifier="identifier"
            :index="index"
        />
      </div>
    </div>
    <div class="pending-ieo-list-item-body">
      <div class="pending-ieo-list-item-body-top">
        <div class="song-author-image-wrap">
          <img v-if="song.authorPhotoUrl" :src="song.authorPhotoUrl" alt="song author image" class="song-author-image">
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="30"
               height="30" viewBox="0 0 30 30"
               class="song-author-image">
            <path id="Path_190" data-name="Path 190"
                  d="M17,2A15,15,0,1,0,32,17,15.017,15.017,0,0,0,17,2Zm9.124,23.655a10.176,10.176,0,0,0-18.238.01A12.44,12.44,0,0,1,4.4,17a12.6,12.6,0,1,1,25.2,0,12.442,12.442,0,0,1-3.476,8.655ZM17,8a5.4,5.4,0,1,0,5.4,5.4A5.4,5.4,0,0,0,17,8Z"
                  transform="translate(-2 -2)" fill="#000"/>
          </svg>
        </div>
        <div class="song-author-info-top">

          <div class="pending-ieo-list-item-info">
            <router-link
                class="pending-ieo-list-item-name-song-link"
                :to="{path: `/song/${song.songId}`}"
                v-if="song.ieo && song.status !== 'FAILED'"
                v-text="song.songTitle"
            ></router-link>

            <span class="pending-ieo-list-item-name-song-link" v-text="song.songTitle" v-else></span>
          </div>

          <div class="pending-ieo-list-item-info">
            <router-link
                :to="{path: `/artist/${song.singerUsername}`}"
                class="pending-ieo-list-item-name-artist-link"
            >
              <span class="pending-ieo-list-item-name-artist" v-text="artistFullName"></span>
            </router-link>
          </div>

        </div>
        <div class="my-song-ieo-edit-btn-wrap">
          <div class="pending-ieo-subscribers-wrap" v-if="song.subscribedIeo === true">
            <svg width="22" height="14" viewBox="0 0 22 14"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                    fill="#FF0090"></path>
            </svg>
            <span class="pending-ieo-subscribers-title"
                  v-text="song.subscribers ? song.subscribers : '0'"></span>
          </div>
          <div class="pending-ieo-subscribers-wrap" v-if="song.subscribedIeo === false">
            <svg width="22" height="14" viewBox="0 0 22 14"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                    fill="#D0D0D0"></path>
            </svg>
            <span class="pending-ieo-subscribers-title"
                  v-text="song.subscribers ? song.subscribers : '0'"></span>
          </div>
        </div>

      </div>
      <div v-if="song.ieo === true">
        <div class="pending-ieo-list-item-body-bottom edit-ieo"
             v-if="song.status === 'NOT_STARTED'">
          <div class="pending-ieo-countdown-wrap"
               v-if="timeIeoStart && preloadFlag">
            <span class="pending-ieo-countdown-title">Time to the IS start:</span>
            <flip-countdown :deadline="timeIeoStart" :showSeconds="false"></flip-countdown>
          </div>
        </div>
        <div class="pending-ieo-list-item-body-bottom failed-ieo"
             v-if="song.status === 'FAILED'">
          <div class="failed-ieo-price-wrap">
            <div class="collected-ieo-price-title">
              <svg width="24" height="24" viewBox="0 0 24 24"
                   fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M11.5789 0C5.18733 0 0 5.18733 0 11.5789C0 17.9704 5.18733 23.1577 11.5789 23.1577C17.9704 23.1577 23.1577 17.9704 23.1577 11.5789C23.1577 5.18733 17.9704 0 11.5789 0ZM11.5789 12.7367C10.942 12.7367 10.421 12.2157 10.421 11.5789V6.94732C10.421 6.31048 10.942 5.78943 11.5789 5.78943C12.2157 5.78943 12.7367 6.31048 12.7367 6.94732V11.5789C12.7367 12.2157 12.2157 12.7367 11.5789 12.7367ZM12.7367 17.3683H10.421V15.0525H12.7367V17.3683Z"
                      fill="#FFB817"/>
              </svg>
              <span class="winners-music-list-item-body-ieo-info">IS is failed</span>
            </div>
          </div>
          <div class="restart-ieo-btn-wrap">
            <button @click="restartISEvent"
                    class="btn btn-hover restart-ieo-btn">Restart Initial Sale
            </button>
          </div>
        </div>
        <div class="pending-ieo-list-item-body-bottom collected-ieo"
             v-if="song.status === 'SUCCESS'">
          <div class="collected-ieo-price-wrap">
            <div class="collected-ieo-price-title">
              <svg xmlns="http://www.w3.org/2000/svg" width="23"
                   height="23" viewBox="0 0 50 50">
                <path class="a"
                      d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                      transform="translate(-0.084)"></path>
              </svg>
              <span class="winners-music-list-item-body-ieo-info">Successful:</span>
            </div>
            <span class="collected-ieo-price"
                  v-text="'€ ' + moneyToFormat"></span>
          </div>
        </div>
        <div class="pending-ieo-list-item-body-bottom pay-awaiting" v-if="song.status === 'PAY_AWAITING'">
          <div class="collected-ieo-price-wrap">
            <div class="collected-ieo-price-title">
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                   viewBox="0 0 50 50">
                <path class="a"
                      d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                      transform="translate(-0.084)"
                      fill="#7ac385"></path>
              </svg>
              <span class="winners-music-list-item-body-ieo-info">Waiting for payment</span>
            </div>
          </div>
          <button class="btn btn-hover" @click="$router.push({path: `/profile/ieo-creating/${song.songId}`, query: {status: song.status, adminId: song.ieoAdminType.id}})">Pay</button>

        </div>
        <div class="pending-ieo-list-item-body-bottom sign-awaiting" v-if="song.status === 'SIGN_AWAITING'">
          <div class="collected-ieo-price-wrap">
            <div class="collected-ieo-price-title">
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                   viewBox="0 0 50 50">
                <path class="a"
                      d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                      transform="translate(-0.084)"
                      fill="#7ac385"></path>
              </svg>
              <span class="winners-music-list-item-body-ieo-info">Sign awaiting</span>
            </div>
          </div>
          <button class="btn btn-hover" @click="$router.push({path: `/profile/ieo-creating/${song.songId}`, query: {status: song.status, adminId: song.ieoAdminType.id}})">Sign</button>
        </div>

        <div class="new-open-ieo bid-present" v-if="song.bidActivity.bidIsPresent === true">
          <div class="pending-ieo-list-item-body-bottom collected-ieo open-ieo" v-if="song.status === 'OPEN'">
            <div class="collected-ieo-price-wrap" v-if="song.bidActivity.bidIsPresent === true">
              <div class="open-ieo-info">
                <div class="open-ieo-info-bidding">
                  <span class="open-ieo-info-bidding-title">Bidding Activity:</span>
                  <div class="open-ieo-info-bidding-list">
                    <div class="open-ieo-info-bidding-list-item open-ieo-info-bidding-percent">
                      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.558385 4.22676C0.294976 4.47066 0.279158 4.88191 0.523056 5.14532C0.766953 5.40873 1.17821 5.42455 1.44162 5.18065L0.558385 4.22676ZM5 1L5.44162 0.523056C5.19242 0.292315 4.80758 0.292315 4.55838 0.523056L5 1ZM8.55838 5.18065C8.82179 5.42455 9.23305 5.40873 9.47694 5.14532C9.72084 4.88191 9.70502 4.47066 9.44162 4.22676L8.55838 5.18065ZM4.35 10C4.35 10.359 4.64101 10.65 5 10.65C5.35898 10.65 5.65 10.359 5.65 10H4.35ZM1.44162 5.18065L5.44162 1.47694L4.55838 0.523056L0.558385 4.22676L1.44162 5.18065ZM4.55838 1.47694L8.55838 5.18065L9.44162 4.22676L5.44162 0.523056L4.55838 1.47694ZM4.35 1V10H5.65V1H4.35Z"
                            fill="#6FAD84"/>
                      </svg>
                      <span class="open-ieo-info-bidding-percent-val"
                            v-text="bidActivityPercentToFormat + '%'"></span>
                    </div>
                    <div class="open-ieo-info-bidding-list-item open-ieo-info-bidding-right_side">
                      <div class="open-ieo-info-bidding-time">
                        <span class="open-ieo-info-bidding-time-value"
                              v-text="song.bidActivity.timeFirstMaxBid"></span>
                      </div>
                      <div class="open-ieo-info-bidding-maxprice">
                        <span class="open-ieo-info-bidding-maxprice-value"
                              v-text="'€' + maxPricePerOneToFormat"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="open-ieo-info-bid">
                  <div class="open-ieo-info-bid-item">
                    <span class="open-ieo-info-bid-item-title">Current min bid per RR:</span>
                    <span class="open-ieo-info-bid-item-value" v-text="'€' + minPricePerOneToFormat"></span>
                  </div>
                  <div class="open-ieo-info-bid-item">
                    <span class="open-ieo-info-bid-item-title">Current max bid per RR:</span>
                    <span class="open-ieo-info-bid-item-value" v-text="'€' + maxPricePerOneToFormat"></span>
                  </div>
                  <div class="open-ieo-info-current-bit">
                    <span class="open-ieo-info-current-bit-title">Current bid Amount:</span>
                    <span class="open-ieo-info-current-bit-value" v-text="'€' + moneyToFormat"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="collected-ieo-open-wrap" v-if="song.bidActivity.bidIsPresent === false">
              <div class="collected-ieo-price-wrap">
                <div class="collected-ieo-price-title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="23"
                       height="23" viewBox="0 0 50 50">
                    <path class="a"
                          d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                          transform="translate(-0.084)"></path>
                  </svg>
                  <span class="winners-music-list-item-body-ieo-info">Initial Sale open:</span>
                </div>
                <span class="collected-ieo-price" v-text="'€ ' + money"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="new-open-old" v-if="song.bidActivity.bidIsPresent === false">
          <div class="pending-ieo-list-item-body-bottom collected-ieo open-ieo" v-if="song.status === 'OPEN'">
            <div class="collected-ieo-price-wrap" v-if="song.bidActivity.bidIsPresent === true">
              <div class="open-ieo-info">
                <div class="open-ieo-info-bidding">
                  <span class="open-ieo-info-bidding-title">Bidding Activity:</span>
                  <div class="open-ieo-info-bidding-list">
                    <div class="open-ieo-info-bidding-list-item open-ieo-info-bidding-percent">
                      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.558385 4.22676C0.294976 4.47066 0.279158 4.88191 0.523056 5.14532C0.766953 5.40873 1.17821 5.42455 1.44162 5.18065L0.558385 4.22676ZM5 1L5.44162 0.523056C5.19242 0.292315 4.80758 0.292315 4.55838 0.523056L5 1ZM8.55838 5.18065C8.82179 5.42455 9.23305 5.40873 9.47694 5.14532C9.72084 4.88191 9.70502 4.47066 9.44162 4.22676L8.55838 5.18065ZM4.35 10C4.35 10.359 4.64101 10.65 5 10.65C5.35898 10.65 5.65 10.359 5.65 10H4.35ZM1.44162 5.18065L5.44162 1.47694L4.55838 0.523056L0.558385 4.22676L1.44162 5.18065ZM4.55838 1.47694L8.55838 5.18065L9.44162 4.22676L5.44162 0.523056L4.55838 1.47694ZM4.35 1V10H5.65V1H4.35Z"
                            fill="#6FAD84"/>
                      </svg>
                      <span class="open-ieo-info-bidding-percent-val"
                            v-text="bidActivityPercentToFormat + '%'"></span>
                    </div>
                    <div class="open-ieo-info-bidding-list-item open-ieo-info-bidding-right_side">
                      <div class="open-ieo-info-bidding-time">
                        <span class="open-ieo-info-bidding-time-value"
                              v-text="song.bidActivity.timeFirstMaxBid"></span>
                      </div>
                      <div class="open-ieo-info-bidding-maxprice">
                        <span class="open-ieo-info-bidding-maxprice-value"
                              v-text="'€' + maxPricePerOneToFormat"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="open-ieo-info-bid">
                  <div class="open-ieo-info-bid-item">
                    <span class="open-ieo-info-bid-item-title">Current min bid per RR:</span>
                    <span class="open-ieo-info-bid-item-value" v-text="'€' + minPricePerOneToFormat"></span>
                  </div>
                  <div class="open-ieo-info-bid-item">
                    <span class="open-ieo-info-bid-item-title">Current max bid per RR:</span>
                    <span class="open-ieo-info-bid-item-value" v-text="'€' + maxPricePerOneToFormat"></span>
                  </div>
                  <div class="open-ieo-info-current-bit">
                    <span class="open-ieo-info-current-bit-title">Current bid Amount:</span>
                    <span class="open-ieo-info-current-bit-value" v-text="'€' + moneyToFormat"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="collected-ieo-open-wrap" v-if="song.bidActivity.bidIsPresent === false">
              <div class="collected-ieo-price-wrap">
                <div class="collected-ieo-price-title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="23"
                       height="23" viewBox="0 0 50 50">
                    <path class="a"
                          d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                          transform="translate(-0.084)"></path>
                  </svg>
                  <span class="winners-music-list-item-body-ieo-info">Initial Sale open:</span>
                </div>
                <span class="collected-ieo-price" v-text="'€ ' + moneyToFormat"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="pending-ieo-list-item-body-bottom create-ieo">
          <button @click="createISEvent"
                       class="create-ieo-btn btn btn-hover">Create Initial Sale
          </button>
        </div>
      </div>
    </div>
    <CropImagePopup ref="cropImagePopup" :defaultImage="'/img/default-song-image.951ee8b2.jpg'" :imageSrc="image.src" @cropImage="cropImage"/>
  </div>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown'
import moment from "moment";
import CropImagePopup from "../popups/CropImagePopup";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  name: "MySongList",
  props: {
    song: {
      type: Object
    },
    current: {
      type: String
    },
    status: String,
    preloadFlag: Boolean,
    favoritesFlag: Boolean,
    index: Number,
    identifier: String
  },
  data() {
    return {
      currencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT,
      artistFullName: '',
      timeIeoStart: null,
      image: {
        src: "",
      },
      changeImageId: null,
      togglePlayerFlag: true,
      mp3: '',
      playerSrc: null,
      formData: new FormData(),
    }
  },
  mixins: [numberFormattingDecimal],
  computed: {
    minPricePerOneToFormat() {
      return this.numberFormattingDecimal(this.song.minPricePerOne)
    },
    maxPricePerOneToFormat() {
      return this.numberFormattingDecimal(this.song.maxPricePerOne)
    },
    totalPlanedToFormat() {
      return this.numberFormattingDecimal(this.song.totalPlanned)
    },
    bidActivityPercentToFormat() {
      return this.song.bidActivity.percent
    },
    moneyToFormat() {
      return this.numberFormattingDecimal(this.song.money)
    },
  },
  methods: {
    restartISEvent() {
      if(this.status === "APPROVED") {
        this.$router.push({path: `/profile/ieo-creating/${this.song.songId}`})
      } else {
        this.$emit('statusFailed')
      }
    },
    createISEvent() {
      if(this.status === "APPROVED") {
        this.$router.push({path: `/profile/ieo-creating/${this.song.songId}`})
      } else {
        this.$emit('statusFailed')
      }
    },
    toggleMusic() {
      if (this.togglePlayerFlag) {
        this.togglePlayerFlag = false
        this.$emit('playingMusic', this.mp3)
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      } else {
        this.togglePlayerFlag = true
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      }
    },
    changeImagePopup(imageId) {
      this.changeImageId = imageId
      this.$refs.cropImagePopup.openPopups()
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      let byteCharacters = atob(b64Data);
      let byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      let blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    cropImage(imageUrl) {
      this.avatarImage = imageUrl
      let block = imageUrl.split(";");
      let contentType = block[0].split(":")[1];// In this case "image/gif"
      let realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
      let blob = this.b64toBlob(realData, contentType);
      this.changeSongImage(blob)
    },
    changeSongImage(file) {
      this.$load(async () => {
        this.formData.set('fileImage', file)
        await this.$api.profileSongs.changeSongImage(this.changeImageId, this.formData)
        this.$emit('updateSongImage')
      }, error => {
        this.error.serverError = error.data.errors[0].message
        this.$refs.serverErrorPopup.openPopups()
      })
    },
  },
  watch: {
    'current'(newVal) {
      if (newVal !== this.mp3) {
        this.$refs[`audioPlayer${this.identifier}${this.index}`].refreshSong()
        this.togglePlayerFlag = true
      }
    }
  },
  mounted() {
    this.mp3 = this.song.songUrlFile
    this.playerSrc = this.mp3
    this.artistFullName = this.song.singerFirstName + ' ' + this.song.singerLastName

    let convertTimeToLocal = moment.utc(this.song.timeIeoStart).local()
    let resultTimeDate = moment(String(convertTimeToLocal)).format('yyyy-MM-DD HH:mm:ss')
    this.timeIeoStart = resultTimeDate
  },
  beforeDestroy() {
    this.togglePlayerFlag = true
  },
  components: {FlipCountdown, CropImagePopup, AudioPlayer}
}
</script>

<style scoped>

.song-author-info-top {
  display: block;
}

.pending-ieo-list-item-name-song-link {
  width: auto;
  color: #000000;
}

a.pending-ieo-list-item-name-song-link {
  transition: .4s ease;
}

a.pending-ieo-list-item-name-song-link:hover {
  color: #FF0090;
}

.pending-ieo-list-item-body-bottom.pay-awaiting, .pending-ieo-list-item-body-bottom.sign-awaiting {
  flex-direction: column;
  align-items: flex-start;
}

.pending-ieo-list-item-body-bottom.pay-awaiting .collected-ieo-price-wrap, .pending-ieo-list-item-body-bottom.sign-awaiting .collected-ieo-price-wrap {
  margin-bottom: 20px;
}

.pending-ieo-list-item-body-bottom.pay-awaiting .btn, .pending-ieo-list-item-body-bottom.sign-awaiting .btn {
  width: 100%;
}

.create-ieo-btn {
  margin-top: auto;
  padding: 15px 0;
}

.pending-ieo-list-item-name-song pre {
  transition: .4s ease;
}

.pending-ieo-list-item-name-song pre:hover {
  color: #FF0090;
}

.audio-player-wrapper {
  height: 45px;
  color: #FFFFFF;
}
</style>