<template>
  <div class="financial-operation-wrapper">
    <div class="financial-operation-top">
      <div class="financial-operation-top__left" @click="flag = !flag">
        <p>See all investors ({{ operation.saleHistoryInvestorInfo.length || '' }})</p>
        <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg"
             :style="{transform: flag ? `rotate(180deg)` : 'rotate(0deg)'}">
          <path
              d="M1.2864 0.620431C1.07677 0.440682 0.761118 0.464905 0.581368 0.674535C0.401619 0.884166 0.425843 1.19982 0.635473 1.37957L1.2864 0.620431ZM4.45965 4L4.13419 4.37957C4.32145 4.54014 4.59785 4.54014 4.78512 4.37957L4.45965 4ZM8.28383 1.37957C8.49346 1.19982 8.51768 0.884166 8.33793 0.674535C8.15818 0.464905 7.84253 0.440682 7.6329 0.620431L8.28383 1.37957ZM0.635473 1.37957L4.13419 4.37957L4.78512 3.62043L1.2864 0.620431L0.635473 1.37957ZM4.78512 4.37957L8.28383 1.37957L7.6329 0.620431L4.13419 3.62043L4.78512 4.37957Z"
          />
        </svg>
      </div>
      <div class="financial-operation-top__right">
        <div class="financial-operation-top__right-text">
          Collected funds
        </div>
        <div class="financial-operation-top__right-total-amount">
          €{{ saleProceeds }}
        </div>
      </div>
    </div>
    <div class="financial-operation__payment-list" v-if="flag">
      <table class="table">
        <thead>
        <tr>
          <th>Username</th>
          <th>Price per {{ currency }} (€)</th>
          <th>Amount of RR</th>
          <th>Closed buy price (€)</th>
          <th>{{ `Purchased ${currency}` }}</th>
          <th>Total sum (€)</th>
        </tr>
        </thead>
        <vue-custom-scrollbar class="scroll-area" :settings="scrollSettings">

          <tbody>

          <InitialSaleHistoryUsersList v-for="(investor, index) in operation.saleHistoryInvestorInfo"
                                       :investor="investor" :key="index"/>

          </tbody>
        </vue-custom-scrollbar>

      </table>
    </div>
    <div class="financial-operation-body__main">
      <div class="financial-operation-body__main-title">


        <div class="financial-operation-body__main-title-img">
          <img v-if="operation.saleHistorySongDetail.songAlbumUrlImage"
               :src="operation.saleHistorySongDetail.songAlbumUrlImage" @error="imgOnError" alt="">
          <img v-else src="../../assets/img/default-song-image.jpg" alt="">
        </div>
        <div class="financial-operation-body__main-title-text">
          <h1>{{ operation.saleHistorySongDetail.songName }}</h1>
          <h3>{{ authorFullName }}</h3>
        </div>
      </div>
      <div class="financial-operation-body__main-progress">
        <div class="financial-operation-body__main-progress-pink" :style="{width: `${progressCount}%`}"></div>
        <div class="financial-operation-body__main-progress-red"></div>
      </div>
      <div class="financial-operation-body__main-list">
        <div class="financial-operation-body__main-item">
          <div class="main-list-item-dott" style="background: #FF9FD5"></div>
          <div class="main-list-item-body">
            <div class="main-list-item-body__text">Royalty rights sold</div>
            <div class="main-list-item-body__amount">{{ soldWithComma + ' ' + currency}}</div>
          </div>
        </div>
        <div class="financial-operation-body__main-item">
          <div class="main-list-item-dott" style="background: #FF0090"></div>
          <div class="main-list-item-body">
            <div class="main-list-item-body__text">Available for sale on the secondary market</div>
            <div class="main-list-item-body__amount">{{ secondaryMarketWithComma + ' ' + currency}}</div>
          </div>
        </div>
        <div class="financial-operation-body__main-item">
          <div class="main-list-item-dott" style="background: #EAEAEA"></div>
          <div class="main-list-item-body">
            <div class="main-list-item-body__text">Royalty rights retained</div>
            <div class="main-list-item-body__amount">{{ ownershipWithComma + ' ' + currency}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="financial-operation-body__amount">
      <div class="financial-operation-body__amount-list-items">
        <div class="amount-list-items__text">Commission</div>
        <div class="amount-list-items__amount">€{{ commission }}</div>
      </div>
<!--      <div class="financial-operation-body__amount-list-items">-->
<!--        <div class="amount-list-items__text">Amount credited to the account</div>-->
<!--        <div class="amount-list-items__amount">€{{ amountCreditedToTheAccount }}</div>-->
<!--      </div>-->
<!--      <div class="financial-operation-body__amount-list-items">-->
<!--        <div class="amount-list-items__text">Сredited to Marketing</div>-->
<!--        <div class="amount-list-items__amount">€{{ marketingUse }}</div>-->
<!--      </div>-->
<!--      <div class="financial-operation-body__amount-list-items">-->
<!--        <div class="amount-list-items__text">Credited to Audio Production</div>-->
<!--        <div class="amount-list-items__amount">€{{ audioProduction }}</div>-->
<!--      </div>-->
<!--      <div class="financial-operation-body__amount-list-items">-->
<!--        <div class="amount-list-items__text">Credited to Video Production</div>-->
<!--        <div class="amount-list-items__amount">€{{ videoProduction }}</div>-->
<!--      </div>-->
<!--      <div class="financial-operation-body__amount-list-items" v-if="operation.financialOperationInitialInfo.tourProduction">-->
<!--        <div class="amount-list-items__text">Credited to Tour Production</div>-->
<!--        <div class="amount-list-items__amount">€{{ tourProduction }}</div>-->
<!--      </div>-->
<!--      <div class="financial-operation-body__amount-list-items privat-use">-->
<!--        <div class="amount-list-items__text">Credited to Private use</div>-->
<!--        <div class="amount-list-items__amount">€{{ privateUse }}</div>-->
<!--      </div>-->
      <div class="financial-operation-body__amount-list-items" v-for="(item, index) in this.operation.financialOperationInitialInfo.purposeTypeMap" :key="index">
        <div class="amount-list-items__text">Сredited to {{item.name}}</div>
        <div class="amount-list-items__amount">€{{ item.amount }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import InitialSaleHistoryUsersList from "./InitialSaleHistoryUsersList";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "FinancialOperationsList",
  props: {
    operation: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      flag: false,
      sold: 23000,
      authorFullName: '',
      scrollSettings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
      currency: process.env.VUE_APP_CURRENCY_NAME_SHORT
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  methods: {
    imgOnError() {
      this.song.authorPhotoUrl = false
    },
    createFullName() {
      this.authorFullName = this.operation.saleHistorySongDetail.firstName + ' ' + this.operation.saleHistorySongDetail.lastName
    },
  },
  computed: {
    saleProceeds() {
      return this.numberFormattingDecimal(this.operation.proceeds)
    },
    soldWithComma() {
      return this.numberFormattingInteger(this.sold)
    },
    secondaryMarketWithComma() {
      return this.numberFormattingInteger(this.operation.saleHistoryIPRInfo.ownerAmount)
    },
    ownershipWithComma() {
      return this.numberFormattingInteger(this.operation.saleHistoryIPRInfo.restAmount)
    },
    progressCount() {
      return Math.floor(this.sold / 1000)
    },
    commission() {
      return this.numberFormattingDecimal(this.operation.financialOperationInitialInfo.commission)
    },
    marketingUse() {
      return this.numberFormattingDecimal(this.operation.financialOperationInitialInfo.purposeTypeMap.marketingUse)
    },
    videoProduction() {
      return this.numberFormattingDecimal(this.operation.financialOperationInitialInfo.purposeTypeMap.videoProduction)
    },
    audioProduction() {
      return this.numberFormattingDecimal(this.operation.financialOperationInitialInfo.purposeTypeMap.songProduction)
    },
    tourProduction() {
      return this.numberFormattingDecimal(this.operation.financialOperationInitialInfo.purposeTypeMap.tourProduction)
    },
    amountCreditedToTheAccount() {
      return this.numberFormattingDecimal(this.operation.financialOperationInitialInfo.creditedAccountAmount)
    },
    privateUse() {
      return this.numberFormattingDecimal(this.operation.financialOperationInitialInfo.privateUse)
    },
  },
  mounted() {
    console.log(typeof this.operation.financialOperationInitialInfo.purposeTypeMap)
    this.sold = this.operation.saleHistoryIPRInfo.soldAmount
    this.createFullName()
  },
  components: {InitialSaleHistoryUsersList}
}
</script>

<style scoped>
.financial-operation-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 50px;
}

.financial-operation-top, .financial-operation-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.financial-operation-top {
  background: #F9F9F9;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px 18px;
}

.financial-operation-body {
  width: 100%;
  margin-top: 10px;
}

.financial-operation-top__left {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: calc(50% - 20px);
}

.financial-operation-top__left:hover {
  text-decoration: underline;
  text-decoration-color: #FF0090;
}

.financial-operation-top__left p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #FF0090;
  margin-bottom: 0;
  margin-right: 5px;
}

.financial-operation-top__left svg path {
  fill: #FF0090;
}

.financial-operation-top__right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 20px);
}

.financial-operation-top__right-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  padding-left: 73px;
}

.financial-operation-top__right-total-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.financial-operation-body__main {
  width: calc(55% - 10px);
  margin-right: 10px;
  border: 1px solid #EFEFEF;
  border-radius: 5px;
  padding: 20px 20px 0 20px;
  margin-top: 10px;
}

.financial-operation-body__main-title {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.financial-operation-body__main-title-img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.financial-operation-body__main-title-img img {
  width: 100%;
  height: 100%;
  border-radius: 13px;
  object-fit: cover;
}

.financial-operation-body__main-title-text {

}

.financial-operation-body__main-title-text h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #000000;
  margin-top: 0;
  margin-bottom: 5px;
}

.financial-operation-body__main-title-text h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #747474;
  margin: 0;
}

.financial-operation-body__main-progress {
  position: relative;
  width: 100%;
  height: 10px;
  background: #F2F2F2;
  border-radius: 30px;
  margin-bottom: 20px;
}

.financial-operation-body__main-progress-pink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 150px;
  background: #FF9FD5;
  border-radius: 30px;
  z-index: 10;
}

.financial-operation-body__main-progress-red {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: #FF0090;
  border-radius: 30px;
  z-index: 5;
}

.financial-operation-body__main-item {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}

.financial-operation-body__main-item:last-child {
  margin-bottom: 0;
}

.main-list-item-dott {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 12px;
}

.main-list-item-body {
  width: calc(100% - 22px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-list-item-body__text {
  width: calc(100% - 78px);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #747474;
}

.main-list-item-body__amount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.financial-operation-body__amount {
  width: 45%;
  border: 1px solid #EFEFEF;
  border-radius: 5px;
  padding-top: 50px;
  margin-top: 10px;
}

.financial-operation-body__amount-list-items {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 20px;
}

.financial-operation-body__amount-list-items:nth-child(5) {
  margin-bottom: 22px;
}

.financial-operation-body__amount-list-items:last-child {
  margin-bottom: 0;
}

.financial-operation-body__amount-list-items.privat-use {
  align-items: center;
  padding: 10px 20px;
  background: #FFF8FC;
  border: 1px solid #FFD3EC;
  border-radius: 0 0 5px 5px;
}

.financial-operation-body__amount-list-items.privat-use .amount-list-items__text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.financial-operation-body__amount-list-items.privat-use .amount-list-items__amount {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.amount-list-items__text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #909090;
}

.amount-list-items__amount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.financial-operation__payment-list {
  border: 1px solid #EFEFEF;
  border-radius: 0 0 5px 5px;
}

.table {
  width: 100%;
  height: auto;
  margin: 0;
}

.table thead {
  background: none;
}

.table tbody {
  max-height: 300px;
}

.table tbody tr {
  border-bottom: none;
  margin-bottom: 15px;
}

.table thead tr th {
  vertical-align: top;
  text-align: center;
  padding: 20px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #909090;
}

.table thead tr th:first-child {
  text-align: start;
}

.table thead tr th:last-child {
  text-align: end;
}

@media only screen and (max-width: 1200px) {
  .table {
    width: 100% !important;
  }

  /*.financial-operation__payment-list th {*/
  /*  white-space: nowrap;*/
  /*}*/

}

@media only screen and (max-width: 900px) {

  .financial-operation__payment-list .table thead tr th {
    padding: 12px;
  }

  }

@media only screen and (max-width: 768px) {
  .table tbody tr {
    margin-bottom: 0;
  }

  .financial-operation-top {
    background: #F9F9F9;
    order: 2;
  }

  .financial-operation-top__left, .financial-operation-top__right {
    width: auto;
  }

  .financial-operation-top__right-text {
    margin-right: 10px;
    padding-left: 0;
  }

  .financial-operation-body__main {
    width: 100%;
    order: 1;
    padding-bottom: 10px;
    margin: 0;
  }

  .financial-operation__payment-list {
    order: 3;
  }

  .financial-operation-body__amount {
    width: 100%;
    order: 4;
  }
}

@media only screen and (max-width: 520px) {
  .financial-operation-body__main {
    padding: 20px 12px 12px 12px;
  }

  .main-list-item-body__text {
    font-size: 12px;
  }

  .financial-operation-top {
    flex-wrap: wrap;
    padding: 16px 12px;
  }

  .financial-operation-top__left {
    justify-content: center;
    border-radius: 6px;
    padding: 9px 0;
    width: 100%;
    background: #FF0090;
    margin-bottom: 12px;

  }

  .financial-operation-top__left p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }

  .financial-operation-top__left svg path {
    fill: #fff;
  }

  .financial-operation-top__right {
    width: 100%;
    padding: 9px 12px;
    background: #FFFFFF;
  }

  .financial-operation-top__right-text {
    font-size: 12px;
  }

  .financial-operation-top__right-total-amount {
    font-size: 14px;
  }

  .financial-operation-body__amount-list-items {
    padding: 0 12px;
  }

  .amount-list-items__text {
    font-size: 12px;
  }

  .amount-list-items__amount {
    font-size: 14px;
  }

  .financial-operation-body__amount-list-items.privat-use .amount-list-items__amount {
    font-size: 16px;
  }

  .financial-operation-body__amount-list-items.privat-use {
    padding: 10px 12px;
    background: #FFFFFF;
  }
}
</style>