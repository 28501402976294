<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open"
         :style="{'border-bottom': selected !== 'None' ? 'unset' : '1px solid #c6c6c6',
                  'border-radius': selected !== 'None' ? '5px 5px 0 0' : '5px'
         }">
      {{ selected }}
    </div>

    <svg class="selected-arrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3945 1L5.69727 5.69727L1 1" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <div class="items" :class="{ selectHide: !open }">
      <div class="item"
           :id="`${idName}-id:none`"
           @click="
          selected = 'None';
          open = false;
          $emit('selectedProvider', 'NONE');
        ">None
      </div>
      <div
          v-for="(option, i) in options"
          :key="i"
          :id="`${idName}-id:${i}`"
          class="item"
          @click="
          selected = option.providerName;
          open = false;
          $emit('selectedProvider', option);
        ">
        <span>{{ option.providerName }}</span>
        <div @click.stop="redirectTo(option)" style="color: #FF0090; text-decoration: underline">View</div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSelectForCreatePm',
  props: {
    options: {
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    idName: String,
  },
  data() {
    return {
      selected: 'None',
      // selected: this.options,
      open: false,
    };
  },
  methods: {
    redirectTo(data) {
      const url = process.env.VUE_APP_BASE_URL
      window.open(`${url}company/${data.providerRouteName}?SR=true`, '_blank');
    }
  },
  mounted() {
    this.$emit("changed", this.selected);
  },
};
</script>

<style scoped>

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 50px;
  line-height: 50px;
}

.custom-select .selected {
  background-color: #0a0a0a;
  border-radius: 6px;
  border: 1px solid #666666;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border: 1px solid #C6C6C6;
  border-radius: 6px 6px 0 0;
}

.selected + .selected-arrow {
  position: absolute;
  top: 22px;
  right: 24px;
  transition: .5s ease;
}

.selected.open + .selected-arrow {
  transform: rotate(180deg);
}

.custom-select .items {
  color: #fff;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
  position: absolute;
  background-color: #0a0a0a;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.selectHide {
  display: none;
}

</style>


<!--how to use? -->

<!--<CustomSelect-->
<!--    :options="['go', 'python', 'rust', 'javascript']"-->
<!--    :default="'go'"-->
<!--    class="select"-->
<!--    @input="alert(displayToKey($event))"-->
<!--/>-->