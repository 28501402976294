<template>
  <v-dialog v-model="dialog">
    <div id="disclaimer-popup-id" class="white-popup popup-form order-popup">
      <div class="form-main-content disclaimer-popup">
        <div class="popup-header">
          <h3 class="form-title">INITIAL SALE AGREEMENT</h3>
          <div class="close-dialog" @click="closeAction">
            <img src="../../assets/img/close.svg" alt="close-icon">
          </div>
        </div>
        <div class="popup-content ">
          <h1 class="popup-agreement-title">INITIAL SALE AGREEMENT # 1</h1>
          <div class="popup-content-container">
            <div class="popup-content-text disclaimer">

              <vuescroll
                  class="scroll-block"
                  :ops="ops"
                  ref="vs"
                  @handle-scroll="handleScroll"
              >
                  <div style="width: 100%; text-align: center">
                    <div v-html="parsedContract" style="font-size: 15px;" class="document-wrapper"></div>
                  </div>
              </vuescroll>

            </div>
          </div>
        </div>
        <div class="button-wrapper">
          <button type="button" id="sellButtonStopLimit1"
                  class="btn btn-hover"
                  @click="confirmAction"
                  :disabled="!checkBox"
                  :class="{'disabled-btn': !checkBox}"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>

  </v-dialog>
</template>

<script>
import vuescroll from 'vuescroll';


export default {
  name: "DisclaimerPmPopup",
  components: {vuescroll},
  props: {
    contractType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      checkBox: false,
      scrollFlag: true,
      buttonText: 'Scroll down to accept',
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          background: '#FB3099',
          size: '3px'
        }
      }

    };
  },
  methods: {
    handleScroll(vertical, horizontal, nativeEvent) {
      if (vertical.process >= 0.98) {
        this.checkBox = true
        this.buttonText = 'I have read and agree to the initial sale agreement'
      }
    },
    changeStylesForPages() {
      const pages = document.getElementsByClassName('format-a4');
      const border = document.getElementsByClassName('pink-border')
      border.forEach((item, index) => {
        console.log(item)
        // item.style.width = '100%'
        item.style.display = 'none'
      })
      pages.forEach((page) => {
        page.style.maxWidth = '200mm'
        page.style.height = 'unset'
        page.style.maxHeight = 'unset'
        page.style.minHeight = '280mm'
        page.style.paddingBottom = '30px'
        const div = document.createElement('div')
        div.style.position = 'absolute'
        div.style.bottom = '0'
        div.style.height = '2.11mm'
        div.style.width = '100%'
        div.style.background = '#FF0090'
        page.appendChild(div)
      })
    },
    closeAction() {
      this.resetState()
      this.dialog = false
    },
    openPopups() {
      this.dialog = true
    },
    resetState() {
      this.checkBox = false
      this.buttonText = 'Scroll down to accept'
      this.$refs["vs"].scrollTo(
          {
            y: 0
          },
          0,
          "easeInQuad"
      );
    },
    confirmAction() {
      this.resetState()
      this.$emit('confirmDisclaimer')
      this.dialog = false
    },

  },
  computed: {
    parsedContract() {
      return atob(this.contractType)
    }
  }
};
</script>

<style scoped>

#disclaimer-popup-id .popup-content {
  padding: 0 26px;
}

.iframe {
  width: 600px;
  height: 3400px;
}

.popup-content-text .popup-agreement-title {
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #000000;
}

.button-wrapper {
  padding: 50px 0;
}

.popup-content-text {
  height: 300px;
  margin-bottom: 20px;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 31px;
  color: #A6A6A6;
  padding: 20px 5px 20px 20px;
  list-style: none;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 5px;
}

.scroll-content-wrapper {
  margin-right: 30px;
}

#disclaimer-popup-id .popup-header {
  position: relative;
  margin-bottom: 70px;
}

#disclaimer-popup-id .close-dialog {
  top: 50%;
  transform: translateY(-50%);
}

.popup-checkbox {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 50px;
}

.popup-checkbox input {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.popup-disclaimer-subtitle {
  font-size: 25px;
  text-align: center;
  margin: 20px 0 0 0;
}

.agreement-contract {
  border: 1px solid #EAEAEA;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 300px;
  margin-bottom: 30px;
}

.agreement-contract h2 {
  margin: 15px 0 0 0;
  padding: 0;
  flex: 20px;
  line-height: 20px;
}

.agreement-contract .agreement-artist-field, .agreement-investor-field {
  width: 50%;
}

.agreement-contract .agreement-artist-field {
  border-right: 1px solid #EAEAEA;
}

.agreement-artist-field h2, .agreement-investor-field h2 {
  text-align: center;
}

.agreement-contract .bold-line {
  display: block;
  height: 1px;
  background-color: #000;
  margin: 100px auto 0;
  width: 80%;
}

.agreement-contract .thin-line {
  margin-top: 120px;
  display: block;
  height: 1px;
  background-color: #EAEAEA;
}

.popup-content-text li {
  list-style-type: none;
}

.agreement-contract-table {
  border: 2px solid #EAEAEA;
  padding: 0 5px;
}

.agreement-contract-table p {
  margin: 0 !important;
}

.agreement-table-row {
  width: 100%;
  border-bottom: 2px solid #EAEAEA;
}

.agreement-table-row:last-child {
  border-bottom: none;
}

.agreement-table-col-sm {
  width: 25%;
  border-right: 2px solid #EAEAEA;
}

.popup-disclaimer-subtitle.exhibit {
  margin-top: 15px;
}

.popup-content-text.disclaimer p, ul li, ol li {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 31px;
  color: #A6A6A6;
}

@media only screen and (max-width: 768px) {
  .document-wrapper {
    zoom: .6;
    -ms-zoom: .6;
  }
}

@media only screen and (max-width: 540px) {
  .popup-content-text {
    padding: 20px 0;
  }

  .iframe {
    width: 300px;
    height: 1800px;
  }

  #disclaimer-popup-id .popup-content {
    padding: 0;
  }

  #disclaimer-popup-id .button-wrapper .btn {
    max-width: 260px;
    padding: 15px;
  }

  .popup-agreement-title {
    font-size: 19px;
    line-height: 26px;
  }

  .popup-content-text.disclaimer p, ul li, ol li {
    font-size: 12px;
    line-height: 19px;
  }

  .popup-content-text.disclaimer h2 {
    font-size: 15px;
    line-height: 21px;
  }

  #disclaimer-popup-id .popup-header {
    margin-bottom: 30px;
  }

  #disclaimer-popup-id .button-wrapper {
    padding: 0 10px 30px 10px;
  }
}

@media only screen and (max-width: 350px) {
  #disclaimer-popup-id .popup-header {
    margin-bottom: 15px;
  }

  #disclaimer-popup-id .popup-agreement-title {
    font-size: 17px;
    line-height: 21px;
  }
}

/* landscape mode adaptive */
@media only screen and (max-height: 420px) and (orientation: landscape) {

  #disclaimer-popup-id .popup-header {
    margin-bottom: 10px;
  }
  #disclaimer-popup-id .popup-agreement-title {
    margin: 10px 0;
    font-size: 20px;
    line-height: 26px;
  }

  #disclaimer-popup-id .popup-content-text {
    margin-bottom: 10px;
    height: 200px;
  }

  #disclaimer-popup-id .button-wrapper {
    padding: 0 0 10px 0;
  }

  #disclaimer-popup-id .button-wrapper .btn {
    padding: 10px 80px;
  }

}

@media only screen and (max-height: 390px) and (orientation: landscape) {

  #disclaimer-popup-id .popup-header {
    padding: 11px 20px;
  }

  #disclaimer-popup-id .form-title {
    font-size: 16px;
    line-height: 20px;
  }

  #disclaimer-popup-id .disclaimer-popup .close-dialog {
    width: 23px;
    height: 23px;
  }
}

@media only screen and (max-height: 375px) and (orientation: landscape) {
  #disclaimer-popup-id .popup-agreement-title {
    margin: 5px 0;
    font-size: 18px;
    line-height: 21px;
  }

  .popup-content-text.disclaimer p, ul li, ol li {
    font-size: 12px;
    line-height: 19px;
  }

  .popup-content-text.disclaimer h2 {
    font-size: 15px;
    line-height: 21px;
  }

  #disclaimer-popup-id .popup-content-text {
    padding: 5px 5px 5px 20px;
  }

}

@media only screen and (max-height: 350px) and (orientation: landscape) {
  #disclaimer-popup-id .popup-content-text {
    height: 165px;
  }
}

@media only screen and (max-width: 380px) {
  #disclaimer-popup-id .button-wrapper .btn {
    max-width: 220px;
  }

  #disclaimer-popup-id .popup-disclaimer-subtitle {
    text-align: start;
    font-size: 23px;
  }
}


</style>