<template>
  <div id="my-content" style="height: 100%">
    <Header/>

    <main id="root" style="height: 100%">
      <div class="profile" :style="{height: '100%', paddingTop: '88px'}">
        <Sidebar v-if="isLogged && showSidebar"/>

        <section
            class="section profile-content"
            style="height: 100%; padding: 0; margin-right: 0; width: 100%"
            :class="{ 'profile-content--full': !showSidebar }"
        >

          <iframe
              src="https://nft.djookyx.com/"
              height="100%"
              style="border-width: 0px; margin-left: 0px; width: calc(100%)"
              @load="finishLoading"
          >
          </iframe>
        </section>
      </div>
    </main>

    <Footer/>
  </div>
</template>

<script>
import Header from "../components/header/Header";
import Sidebar from "../components/profile/Sidebar.vue";
import Footer from "../components/footer/Footer";
import {mapGetters} from "vuex";

export default {
  components: {
    Header,
    Footer,
    Sidebar,
  },
  data() {
    return {
      account: null,
      showSidebar: false,
    };
  },
  created() {
    this.getUserAccount();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.checkRouter(to.path);
    });
  },
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged',
    }),
  },
  mounted() {

  }
};
</script>
