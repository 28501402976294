<template>
  <div class=""></div>
</template>

<script>
export default {
  name: "RedirectPage",
  created() {
    window.open('djookyxlinking://')
  }
}
</script>

<style scoped>

</style>