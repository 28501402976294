<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected }}
    </div>

    <svg class="selected-arrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3945 1L5.69727 5.69727L1 1" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <div class="items" :class="{ selectHide: !open }">
      <div
          v-for="(option, i) in options"
          :key="i"
          :id="`${idName}-id:${i}`"
          class="item"
          @click="
          selected = option.publicName;
          open = false;
          $emit('selectedProvider', option);
        ">
        {{ option.publicName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSelectForAdminType',
  props: {
    options: {
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    idName: String,
  },
  data() {
    return {
      selected: null,
      // selected: this.options,
      open: false,
    };
  },
  mounted() {
    this.$emit("changed", this.selected);
    this.selected = this.options[0].publicName;
  },
};
</script>

<style scoped>

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 50px;
  line-height: 50px;
}

.custom-select .selected {
  background: #fff;
  color: #222;
  font-size: 20px;
  padding: 0 0 0 45px;
  height: 100%;
  font-weight: 300;
  text-transform: none;
  border: 1px solid #c6c6c6;
  cursor: pointer;
}

.custom-select .selected.open {
  border: 1px solid #C6C6C6;
  border-radius: 6px 6px 0 0;
}

.selected + .selected-arrow {
  position: absolute;
  top: 22px;
  right: 24px;
  transition: .5s ease;
}

.selected.open + .selected-arrow {
  transform: rotate(180deg);
}

.custom-select .items {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  color: #131415;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #f5f5f5;
}

.selectHide {
  display: none;
}

</style>


<!--how to use? -->

<!--<CustomSelect-->
<!--    :options="['go', 'python', 'rust', 'javascript']"-->
<!--    :default="'go'"-->
<!--    class="select"-->
<!--    @input="alert(displayToKey($event))"-->
<!--/>-->