import { render, staticRenderFns } from "./Trading.vue?vue&type=template&id=3dc6da8c&scoped=true"
import script from "./Trading.vue?vue&type=script&lang=js"
export * from "./Trading.vue?vue&type=script&lang=js"
import style0 from "./Trading.vue?vue&type=style&index=0&id=3dc6da8c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dc6da8c",
  null
  
)

export default component.exports