import { render, staticRenderFns } from "./CompanyProfile.vue?vue&type=template&id=71f20b36&scoped=true"
import script from "./CompanyProfile.vue?vue&type=script&lang=js"
export * from "./CompanyProfile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f20b36",
  null
  
)

export default component.exports