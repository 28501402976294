<template>
  <div id="my-content">
    <Header/>
    <main id="root">

      <div class="profile">
        <sidebar/>


        <div class="profile-content ieo-creating">

          <div class="profile-content-top">
            <h1 class="ieo-creating-title-top page-title separator">INITIAL SALE CREATING</h1>
            <div class="profile-content-top__back"
                 v-if="stepFlag > 1 && songStatus !== 'PAY_AWAITING' && songStatus !== 'SIGN_AWAITING'"
                 @click="stepFlag = stepFlag - 1">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2 6.99988L1.29289 6.29277C0.902369 6.6833 0.902369 7.31646 1.29289 7.70698L2 6.99988ZM7.70709 2.707C8.09761 2.31648 8.09761 1.68331 7.70709 1.29279C7.31657 0.902263 6.6834 0.902264 6.29288 1.29279L7.70709 2.707ZM6.29288 12.707C6.6834 13.0975 7.31657 13.0975 7.70709 12.707C8.09761 12.3164 8.09761 11.6833 7.70709 11.2928L6.29288 12.707ZM2.70711 7.70698L7.70709 2.707L6.29288 1.29279L1.29289 6.29277L2.70711 7.70698ZM7.70709 11.2928L2.70711 6.29277L1.29289 7.70698L6.29288 12.707L7.70709 11.2928Z"
                    fill="#EC008C"/>
              </svg>
              <span>Back</span>
            </div>
          </div>

          <StepProgressBar :current-step="stepFlag" :step-names="stepNames" :total-of-steps="3"/>
          <ValidationObserver v-slot="{ handleSubmit }" v-if="stepFlag === 1 && !this.$route.query.status">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <div class="ieo-creating-money-wrap">

                <div class="ieo-creating-header">
                  <h2 class="ieo-creating-title">Who will administer the song ?</h2>
                  <span class="info hint hint--bottom--left"
                        data-hint="Song administrator collects royalties generated from the song on behalf of the artist and manages the rights of an artist within the music industry.">
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
                                              d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                                              fill="black"/>
                                    </svg>
                                </span>
                </div>
                <div class="ieo-creating-admin" v-if="adminTypeList">
                  <CustomSelectForAdminType :options="adminTypeList"
                                            @selectedProvider="selectAdminType"
                                            :idName="'admin-type'"
                  />

                </div>

                <div class="ieo-creating-header">
                  <h2 class="ieo-creating-title">duration of auction</h2>
                </div>
                <div class="ieo-creating__duration-wrap">

                  <div class="ieo-creating__duration-item">
                    <label class="ieo-creating__duration-item-border"
                           :class="{'duration__active-checkbox': formData.ieoDuration === 'ONE_WEEK'}"
                    >
                      <span class="ieo-creating__duration-item-fill"></span>
                      <input type="radio"
                             value="ONE_WEEK"
                             v-model="formData.ieoDuration"
                      >
                    </label>
                    <span class="ieo-creating__duration-item-text">1 week</span>
                  </div>

                  <div class="ieo-creating__duration-item">
                    <label class="ieo-creating__duration-item-border"
                           :class="{'duration__active-checkbox': formData.ieoDuration === 'TWO_WEEKS'}"
                    >
                      <span class="ieo-creating__duration-item-fill"></span>
                      <input type="radio"
                             value="TWO_WEEKS"
                             v-model="formData.ieoDuration"
                      >
                    </label>
                    <span class="ieo-creating__duration-item-text">2 weeks</span>
                  </div>

                  <div class="ieo-creating__duration-item">
                    <label class="ieo-creating__duration-item-border"
                           :class="{'duration__active-checkbox': formData.ieoDuration === 'THREE_WEEKS'}"
                    >
                      <span class="ieo-creating__duration-item-fill"></span>
                      <input type="radio"
                             value="THREE_WEEKS"
                             v-model="formData.ieoDuration"
                      >
                    </label>
                    <span class="ieo-creating__duration-item-text">3 weeks</span>
                  </div>

                  <div class="ieo-creating__duration-item">
                    <label class="ieo-creating__duration-item-border"
                           :class="{'duration__active-checkbox': formData.ieoDuration === 'FOUR_WEEKS'}"
                    >
                      <span class="ieo-creating__duration-item-fill"></span>
                      <input type="radio"
                             value="FOUR_WEEKS"
                             v-model="formData.ieoDuration"
                      >
                    </label>
                    <span class="ieo-creating__duration-item-text">4 weeks</span>
                  </div>

                </div>


                <div class="ieo-creating-header">
                  <h2 class="ieo-creating-title">What is the purpose of this money?</h2>
                  <span class="info hint hint--bottom--left"
                        data-hint="This is to indicate for what purposes you are collecting money. Here you have to disclose where funds from primary sale will be transfered to (audio production, marketig, private matters etc.).">
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
                                              d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                                              fill="black"/>
                                    </svg>
                                </span>
                </div>
                <div class="ieo-creating-money">
                  <div class="ieo-creating-money-item"
                       id="ieo-creating-money-item-marketingUse"
                       :class="{relative: marketingRelativeFlag}"
                  >
                    <span class="ieo-creating-money-item-title">Marketing:</span>
                    <div class="ieo-creating-money-select-wrapper">

                      <CustomSelectForCreatePm :options="marketingProviders"
                                               @selectedProvider="selectedMarketingProvider"
                                               :idName="'marketing-use'"
                      />

                      <div class="package-list"
                           v-if="formData.purposeTypeMap.marketingUse.serviceProviderLevelPrice !== null">
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.marketingUse.serviceProviderLevelPrice === 'LOW_PRICE' ? 'block' : 'none'}"></div>
                            <input id="marketing-base-package" type="radio" name="document-type" value="LOW_PRICE"
                                   v-model="formData.purposeTypeMap.marketingUse.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Silver</span>
                          <span class="package-list-item-amount">{{ `€ ${marketingLowPriceToFormat}` }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="marketingProvidersPackages.lowPriceInfo"></span>
                          </v-tooltip>
                        </div>
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.marketingUse.serviceProviderLevelPrice === 'MEDIUM_PRICE' ? 'block' : 'none'}"></div>
                            <input id="marketing-medium-package" type="radio" name="document-type"
                                   value="MEDIUM_PRICE"
                                   v-model="formData.purposeTypeMap.marketingUse.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Gold</span>
                          <span class="package-list-item-amount">{{
                              `€ ${marketingMediumPriceToFormat}`
                            }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="marketingProvidersPackages.mediumPriceInfo"></span>
                          </v-tooltip>
                        </div>
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.marketingUse.serviceProviderLevelPrice === 'HIGH_PRICE' ? 'block' : 'none'}"></div>
                            <input id="marketing-high-package" type="radio" name="document-type" value="HIGH_PRICE"
                                   v-model="formData.purposeTypeMap.marketingUse.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Platinum</span>
                          <span class="package-list-item-amount">{{
                              `€ ${marketingHighPriceToFormat}`
                            }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="marketingProvidersPackages.highPriceInfo"></span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="ieo-creating-money-item" id="ieo-creating-money-item-songProdNullable"
                       :class="{relative: audioRelativeFlag}">
                    <span class="ieo-creating-money-item-title">Audio production:</span>
                    <div class="ieo-creating-money-select-wrapper">
                      <CustomSelectForCreatePm :options="songProviders"
                                               @selectedProvider="selectedSongProductionProvider"
                                               :idName="'audio-production'"/>
                      <div class="package-list"
                           v-if="formData.purposeTypeMap.songProduction.serviceProviderLevelPrice !== null">
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.songProduction.serviceProviderLevelPrice === 'LOW_PRICE' ? 'block' : 'none'}"></div>
                            <input id="audio-base-package" type="radio" name="document-type" value="LOW_PRICE"
                                   v-model="formData.purposeTypeMap.songProduction.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Silver</span>
                          <span class="package-list-item-amount">{{ `€ ${songLowPriceToFormat}` }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="songProvidersPackages.lowPriceInfo"></span>
                          </v-tooltip>
                        </div>
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.songProduction.serviceProviderLevelPrice === 'MEDIUM_PRICE' ? 'block' : 'none'}"></div>
                            <input id="audio-medium-package" type="radio" name="document-type" value="MEDIUM_PRICE"
                                   v-model="formData.purposeTypeMap.songProduction.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Gold</span>
                          <span class="package-list-item-amount">{{ `€ ${songMediumPriceToFormat}` }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="songProvidersPackages.mediumPriceInfo"></span>
                          </v-tooltip>
                        </div>
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.songProduction.serviceProviderLevelPrice === 'HIGH_PRICE' ? 'block' : 'none'}"></div>
                            <input id="audio-high-package" type="radio" name="document-type" value="HIGH_PRICE"
                                   v-model="formData.purposeTypeMap.songProduction.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Platinum</span>
                          <span class="package-list-item-amount">{{ `€ ${songHighPriceToFormat}` }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="songProvidersPackages.highPriceInfo"></span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="ieo-creating-money-item" id="ieo-creating-money-item-videoProdNullable"
                       :class="{relative: videoRelativeFlag}">
                    <span class="ieo-creating-money-item-title">Video production:</span>
                    <div class="ieo-creating-money-select-wrapper">
                      <CustomSelectForCreatePm :options="videoProviders"
                                               @selectedProvider="selectedVideoProductionProvider"
                                               :idName="'video-production'"/>
                      <div class="package-list"
                           v-if="formData.purposeTypeMap.videoProduction.serviceProviderLevelPrice !== null">
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.videoProduction.serviceProviderLevelPrice === 'LOW_PRICE' ? 'block' : 'none'}"></div>
                            <input id="video-base-package" type="radio" name="document-type" value="LOW_PRICE"
                                   v-model="formData.purposeTypeMap.videoProduction.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Silver</span>
                          <span class="package-list-item-amount">{{ `€ ${videoLowPriceToFormat}` }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="videoProvidersPackages.lowPriceInfo"></span>
                          </v-tooltip>
                        </div>
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.videoProduction.serviceProviderLevelPrice === 'MEDIUM_PRICE' ? 'block' : 'none'}"></div>
                            <input id="video-medium-package" type="radio" name="document-type" value="MEDIUM_PRICE"
                                   v-model="formData.purposeTypeMap.videoProduction.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Gold</span>
                          <span class="package-list-item-amount">{{ `€ ${videoMediumPriceToFormat}` }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="videoProvidersPackages.mediumPriceInfo"></span>
                          </v-tooltip>
                        </div>
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.videoProduction.serviceProviderLevelPrice === 'HIGH_PRICE' ? 'block' : 'none'}"></div>
                            <input id="video-high-package" type="radio" name="document-type" value="HIGH_PRICE"
                                   v-model="formData.purposeTypeMap.videoProduction.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Platinum</span>
                          <span class="package-list-item-amount">{{ `€ ${videoHighPriceToFormat}` }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="videoProvidersPackages.highPriceInfo"></span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>

                    <!--                    <input v-if="!formData.purposeTypeMap.videoProduction" v-model="videoProdNullable"-->
                    <!--                           placeholder="recommended: € 7,000" class="ieo-creating-money-item-input money-input"-->
                    <!--                           type="number"/>-->
                    <!--                    <money-->
                    <!--                        v-else-->
                    <!--                        placeholder="hello"-->
                    <!--                        ref="videoProd"-->
                    <!--                        v-model="formData.purposeTypeMap.videoProduction"-->
                    <!--                        v-bind="money"-->
                    <!--                        class="ieo-creating-money-item-input money-input 123"-->
                    <!--                        name="videoProduction"-->
                    <!--                        @blur.native="countDifferenceAmount"-->
                    <!--                    ></money>-->
                  </div>
                  <div class="ieo-creating-money-item" id="ieo-creating-money-item-tourProdNullable"
                       :class="{relative: tourRelativeFlag}">
                    <span class="ieo-creating-money-item-title">Tour Management & Production:</span>
                    <div class="ieo-creating-money-select-wrapper">
                      <CustomSelectForCreatePm :options="tourProviders"
                                               @selectedProvider="selectedTourProductionProvider"
                                               :idName="'tour-production'"/>
                      <div class="package-list"
                           v-if="formData.purposeTypeMap.tourProduction.serviceProviderLevelPrice !== null">
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.tourProduction.serviceProviderLevelPrice === 'LOW_PRICE' ? 'block' : 'none'}"></div>
                            <input id="tour-base-package" type="radio" name="document-type" value="LOW_PRICE"
                                   v-model="formData.purposeTypeMap.tourProduction.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Silver</span>
                          <span class="package-list-item-amount">{{ `€ ${tourLowPriceToFormat}` }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="tourProvidersPackages.lowPriceInfo"></span>
                          </v-tooltip>
                        </div>
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.tourProduction.serviceProviderLevelPrice === 'MEDIUM_PRICE' ? 'block' : 'none'}"></div>
                            <input id="video-medium-package" type="radio" name="document-type" value="MEDIUM_PRICE"
                                   v-model="formData.purposeTypeMap.tourProduction.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Gold</span>
                          <span class="package-list-item-amount">{{ `€ ${tourMediumPriceToFormat}` }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="tourProvidersPackages.mediumPriceInfo"></span>
                          </v-tooltip>
                        </div>
                        <div class="package-list-item">
                          <label class="package-list-item-border">
                            <div class="package-list-item-fill"
                                 :style="{display: formData.purposeTypeMap.tourProduction.serviceProviderLevelPrice === 'HIGH_PRICE' ? 'block' : 'none'}"></div>
                            <input id="video-high-package" type="radio" name="document-type" value="HIGH_PRICE"
                                   v-model="formData.purposeTypeMap.tourProduction.serviceProviderLevelPrice"
                            >
                          </label>
                          <span>Platinum</span>
                          <span class="package-list-item-amount">{{ `€ ${tourHighPriceToFormat}` }}</span>
                          <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"
                                     :open-on-hover="toggleTooltipShowEvent">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">
                                  </path>
                                </svg>
                              </v-btn>
                            </template>
                            <span v-html="tourProvidersPackages.highPriceInfo"></span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>

                    <!--                    <input v-if="!formData.purposeTypeMap.videoProduction" v-model="videoProdNullable"-->
                    <!--                           placeholder="recommended: € 7,000" class="ieo-creating-money-item-input money-input"-->
                    <!--                           type="number"/>-->
                    <!--                    <money-->
                    <!--                        v-else-->
                    <!--                        placeholder="hello"-->
                    <!--                        ref="videoProd"-->
                    <!--                        v-model="formData.purposeTypeMap.videoProduction"-->
                    <!--                        v-bind="money"-->
                    <!--                        class="ieo-creating-money-item-input money-input 123"-->
                    <!--                        name="videoProduction"-->
                    <!--                        @blur.native="countDifferenceAmount"-->
                    <!--                    ></money>-->
                  </div>
                  <div class="ieo-creating-money-item ieo-creating-money-item-other"
                       id="ieo-creating-money-item-privateUse"
                  >

                      <span class="ieo-creating-money-item-title tooltip-anchor"
                            ref="privatUseTitle">Private use:</span>

                    <!--                          support for ukraine tooltip-->

                    <!--                    <span class="ieo-creating-money-item-title tooltip-anchor" ref="privatUseTitle">-->
                    <!--                      Support for Ukraine:-->
                    <!--                    <v-tooltip bottom :open-on-focus="!toggleTooltipShowEvent"-->
                    <!--                               :open-on-hover="toggleTooltipShowEvent"-->
                    <!--                               max-width="200"-->
                    <!--                    >-->
                    <!--                      <template v-slot:activator="{ on, attrs }">-->
                    <!--                        <v-btn-->
                    <!--                            v-bind="attrs"-->
                    <!--                            v-on="on"-->
                    <!--                        >-->
                    <!--                          <svg class="package-list-info-item" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z">-->
                    <!--                            </path>-->
                    <!--                          </svg>-->
                    <!--                        </v-btn>-->
                    <!--                      </template>-->
                    <!--                      <span>All funds collected for Ukrainian support will be passed on for humanitarian aid. All receipts and proof will be posted on our social media and news articles.</span>-->
                    <!--                    </v-tooltip>-->
                    <!--                    </span>-->

                    <!--                       end  support for ukraine tooltip-->

                    <div class="ieo-creating__private-use-wrap">
                      <VueAutoNum
                          class="ieo-creating-money-item-input money-input"
                          placeholder="€ 0.00"
                          ref="privatUse"
                          name="privateUse"
                          id="privat_use"
                          v-model="formData.privateUse"
                          @keyup.native="changeBasicPrivateUse"
                          :options="numParams"
                      ></VueAutoNum>
                      <!--                      <money class="ieo-creating-money-item-input money-input"-->
                      <!--                             placeholder="hello"-->
                      <!--                             ref="privatUse"-->
                      <!--                             v-model="formData.privateUse"-->
                      <!--                             v-bind="money"-->
                      <!--                             name="privateUse"-->
                      <!--                             @keyup.native="changeBasicPrivateUse"-->
                      <!--                             id="privat_use"-->
                      <!--                      ></money>-->
                      <div class="ieo-creating__private-use-select">
                        <GeneralSelect :identifier="'private-use-select'"
                                       :options="needsList"
                                       :current="currentNeed"
                                       @changed="setNeeds"
                        />
                      </div>
                    </div>
                    <p class="error-message absolute" v-if="!checkPositiveNumber">Number must be positive</p>
                  </div>

                  <!--                  <div class="ieo-creating-money-description">-->
                  <!--                    <p>Song administrator collects royalties generated from the song on behalf of the artist and manages-->
                  <!--                      the rights of an artist within the music marketplace. You could choose either Djooky Records or-->
                  <!--                      any-->
                  <!--                      other third party to be administer of your song.</p>-->
                  <!--                  </div>-->

                </div>
              </div>
              <div class="ieo-creating-info">

                <div class="ieo-creating-header">
                  <h2 class="ieo-creating-title ieo-creating-title__rights">
                    Your song copyrights <br/>
                    <span class="ieo-creating-title__right">100% = 100,000 {{ currencyName }}</span>
                  </h2>
                  <span class="info hint hint--bottom--left"
                        data-hint="Here you have to indicate how many shares of royalty rights (RR's) you want to offer for sale to investors and what your minimum price per RR is. Remember you cannot offer more than 49.9% of the total RR's in the song.">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
                              d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                              fill="black"/>
                    </svg>
                  </span>
                </div>

                <div class="calculate-title"> 1% = 1.000 {{ currencyName }}'s</div>

                <div class="ieo-creating__calculate">

                  <div class="ieo-creating__calculate-item">
                    <div class="ieo-creating__input-wrap calculate-input-wrap">
                      <ValidationProvider rules="max_value:49.900" name="totalAmount" v-slot="{ errors }">
                        <!--                        <money class="ieo-creating__input calculate-input"-->
                        <!--                               id="id__calculate-input"-->
                        <!--                               name="totalAmount"-->
                        <!--                               ref="calculateInput"-->
                        <!--                               v-model="totalIPR"-->
                        <!--                               v-bind="moneyPercent"-->
                        <!--                        ></money>-->

                        <VueAutoNum
                            class="ieo-creating__input calculate-input"
                            id="id__calculate-input"
                            name="totalAmount"
                            ref="calculateInput"
                            placeholder="0.000"
                            v-model="totalIPR"
                            :options="numParamsPercent"
                        ></VueAutoNum>

                        <span class="error invite-email-error">{{ errors[0] }}</span>

                      </ValidationProvider>
                      <div class="calculate-input__total">{{ currencyName }}
                        <span class="calculate-input__total-val">{{ totalAmountToFormat }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="ieo-creating__calculate-item calculate-item__per_one desktop">
                    <span class="ieo-creating__calculate-description" v-text="`1 ${currencyName} =`"></span>
                    <div class="ieo-creating__input-wrap total-per_one-wrap">
                      <input class="ieo-creating__input total-per_one"
                             type="number"
                             name="pricePerIpr"
                             ref="totalPerOne"
                             required readonly
                             :value="pricePerIprVal"
                      >
                    </div>
                  </div>

                </div>

                <div class="ieo-creating__calculate-bottom">

                  <div class="ieo-creating__calculate-btn-wrap calculate-bottom-item">
                    <button class="btn ieo-creating__calculate-btn"
                            type="button"
                            :class="{'hover': !calculateFlag}"
                            @click="countDifferenceAmount"
                    >Calculate
                    </button>
                  </div>

                  <div class="ieo-creating__calculate-item calculate-item__per_one calculate-bottom-item mobile">
                    <span class="ieo-creating__calculate-description" v-text="`1 ${currencyName} =`"></span>
                    <div class="ieo-creating__input-wrap total-per_one-wrap">
                      <input class="ieo-creating__input total-per_one"
                             type="number"
                             name="pricePerIpr"
                             ref="totalPerOne"
                             required readonly
                             :value="pricePerIprVal"
                      >
                    </div>
                  </div>

                </div>

              </div>

              <div class="ieo-creating-total">

                <div class="ieo-creating-header">
                  <h2 class="ieo-creating-title">you need to sell in total</h2>
                  <div class="ieo-creating__total-sum desktop"
                       v-text="`${currencyName} ${totalAmountShow} = € ${totalMoneyToFormat}`"
                  >
                  </div>
                </div>

                <div class="ieo-creating-total-content">

                  <div class="ieo-creating__total-sum mobile"
                       v-text="`${currencyName} ${totalAmountShow} = € ${totalMoneyToFormat}`"
                  >
                  </div>

                  <div class="ieo-creating__resume-wrap">

                    <div class="ieo-creating__resume-item">
                      <div class="ieo-creating__resume-title">Your funds</div>
                      <div class="ieo-creating__resume-value" v-text="`€ ${privateUseToFormat}`"></div>
                    </div>

                    <div class="ieo-creating__resume-item">
                      <div class="ieo-creating__resume-title">
                        Commission
                        <span class="info hint hint--bottom--left"
                              data-hint="To make sure all payments are safe and transparent, we are using an escrow agent."
                        >
                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
                                    d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                                    fill="black"/>
                          </svg>
                        </span>
                      </div>
                      <div class="ieo-creating__resume-value" v-text="`€ ${countFeeToFormat}`"></div>
                    </div>

                  </div>
                </div>

              </div>
              <div class="ieo-creating-checkbox-wrap">

                <div class="profile-checkbox-item">
                  <GeneralCustomCheckBox :checkBoxId="'create_ieo-agree'"
                                         @checked="checkedChangeFirst"
                                         ref="createIeoCheckboxFirst"
                  />
                  <p class="profile-checkbox-description">{{ agreeText }}</p>
                </div>

                <!--                <div class="ieo-creating-checkbox-item">-->
                <!--                  <label class="ieo-creating-checkbox-label" id="custom-checkbox51">-->
                <!--                    <CustomCheckbox class="ieo-checkbox" @checked="firstCheckbox = !firstCheckbox" name="i_agree1"/>-->
                <!--                    <div>{{ agreeText }}</div>-->
                <!--                  </label>-->
                <!--                </div>-->

                <!--                <div class="ieo-creating-checkbox-item">-->
                <!--                  <label class="ieo-creating-checkbox-label" id="custom-checkbox41">-->
                <!--                    <CustomCheckbox class="ieo-checkbox" @checked="secondCheckbox = !secondCheckbox" name="i_agree1"/>-->
                <!--                    <div>{{ anotherText }}</div>-->
                <!--                  </label>-->

                <!--                </div>-->
              </div>

              <div class="ieo-creating-btn-wrap">
                <p class="error" v-if="firstCheckBox && !calculateFlag">Please, click to calculate</p>
                <button
                    type="submit"
                    class="btn btn-hover ieo-creating-btn"
                    :class="{'disabled-btn': checkForDisable}"
                    id="ieo-creating-btn-id"
                    :disabled="checkForDisable"
                >Next
                </button>
              </div>
            </form>
          </ValidationObserver>
          <div class="preloader-wrapper" style="height: unset" v-if="!loadFlag">
            <v-progress-circular
                :size="75"
                :width="8"
                color="#FF0090"
                indeterminate
            ></v-progress-circular>
          </div>
          <IeoCreatingDocumentSign v-if="stepFlag === 2" @documentSigned="documentSignedAgreed"
                                   :ieoAdminId="this.formData.ieoAdminId"/>
          <IeoCreatingPayment :price="paymentData.price" :userToken="paymentUrl" v-if="stepFlag === 3"
                              @confirmWithWallet="documentSignedAgreedWidthWallet"/>
        </div>

      </div>
      <MangopayCallErrorPopup ref="mangopayCallErrorPopup"/>
      <ServerErrorPopup ref="serverErrorPopup" :errorMessage="errors.message"/>
    </main>
    <Footer/>
  </div>

</template>

<script>
import Sidebar from "./Sidebar";
import Header from "../header/Header";
import Footer from "../footer/Footer";
// import {Money} from 'v-money'
import ServerErrorPopup from "../popups/ServerErrorPopup";
import MangopayCallErrorPopup from "../popups/MangopayCallErrorPopup";
import GeneralCustomCheckBox from "@/components/inputs/GeneralCustomCheckBox";
import StepProgressBar from "./StepProgressBar";
import IeoCreatingDocumentSign from "./IeoCreatingDocumentSign";
import IeoCreatingPayment from "./IeoCreatingPayment";
import CustomSelectForCreatePm from "../inputs/CustomSelectForCreatePm";
import CustomSelectForAdminType from "../inputs/CustomSelectForAdminType";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";
import GeneralSelect from "@/components/inputs/GeneralSelect";

export default {
  name: "IeoCreating",
  data() {
    return {
      loadFlag: true,
      currencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT,
      currencyNameLong: process.env.VUE_APP_CURRENCY_NAME_LONG,
      adminTypeList: null,
      percentWatcher: 1,
      totalAmountShow: 0,
      screenWidthFlag: null,
      stepFlag: 1,
      stepNames: ['Initial Sale Details', 'Document signature', 'Payment'],
      privateUseForShow: 0,
      calculateFlag: false,
      errors: {
        name: null,
        message: ''
      },
      price: '',
      numParams: {
        modifyValueOnWheel: false, // turn off wheel mode
        digitGroupSeparator: '.',
        decimalCharacter: ',',
        decimalPlaces: 2,
        currencySymbolPlacement: 'p', // prefix || suffix
        currencySymbol: '€\u00a0',
        overrideMinMaxLimits: null,
        minimumValue: '0',
        maximumValue: '10000000000',
      },
      numParamsPercent: {
        modifyValueOnWheel: false, // turn off wheel mode
        digitGroupSeparator: '.',
        decimalCharacter: ',',
        decimalPlaces: 3,
        currencySymbolPlacement: 'p', // prefix || suffix
        currencySymbol: '', // \u00a0%
        overrideMinMaxLimits: null,
        minimumValue: '0',
        maximumValue: '100',
      },
      options: [],
      totalIPR: 0,
      marketingProvidersPackages: {
        lowPrice: null,
        mediumPrice: null,
        highPrice: null,
      },
      songProvidersPackages: {
        lowPrice: null,
        mediumPrice: null,
        highPrice: null,
      },
      videoProvidersPackages: {
        lowPrice: null,
        mediumPrice: null,
        highPrice: null,
      },
      tourProvidersPackages: {
        lowPrice: null,
        mediumPrice: null,
        highPrice: null,
      },
      marketingProviders: null,
      songProviders: null,
      videoProviders: null,
      tourProviders: null,
      marketingRelativeFlag: false,
      audioRelativeFlag: false,
      videoRelativeFlag: false,
      tourRelativeFlag: false,
      needsList: [],
      currentNeed: null,
      firstCheckBox: false,
      disableSubmitBtn: false,
      label: 'Total amount',
      actionType: true,
      privatUseBasic: 0,

      paymentData: {
        amount: 0,
        price: 0,
        paymentGoal: 'PLATFORM_FEE',
        ieoUuid: this.$route.params.id,
        paymentType: '',
        initialFinancialType: null
      },
      formData: {
        totalAmount: 0,
        pricePerIpr: 1,
        ieoTypeDTO: 'DUTCH',
        ieoAdminId: 0,
        purposeTypeMap: {
          marketingUse: {
            serviceProviderId: null,
            amount: null,
            serviceProviderLevelPrice: null,
          },
          songProduction: {
            serviceProviderId: null,
            amount: null,
            serviceProviderLevelPrice: null,
          },
          videoProduction: {
            serviceProviderId: null,
            amount: null,
            serviceProviderLevelPrice: null,
          },
          tourProduction: {
            serviceProviderId: null,
            amount: null,
            serviceProviderLevelPrice: null,
          },
        },
        privateUsePurposes: [],
        songUuid: '',
        privateUse: 0,
        ieoDuration: 'ONE_WEEK',
      },
      totalDifference: 0,
      totalMoneyForShow: '0',
      // totalMoneyForShowWithComas: '',
      commission: 0,
      countFee: 0,
      paymentUrl: null,
      agreeTextShow: '',
      songStatus: null,
      // anotherText: 'I agree and accept the Publishing administration agreement. By clicking this checkbox I accept to be bound by a set of legal terms and conditions of the Publishing administration agreement.',
      agreeText: 'I guarantee and assure to the benefit of Djooky and of every User of Djooky that I am duly and validly authorized, with full power to act on behalf of any third party, including, but not limited to, any co-authors, band members, or others, who have any right, title or interest in or to the song for which I am hereby offering royalty rights for sale, to offer and sell such royalty rights to any third party, including, without limitation, through Djooky.'
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  methods: {
    selectAdminType(data) {
      this.formData.ieoAdminId = data.id;
      console.log(this.formData.ieoAdminId);
    },
    selectedMarketingProvider(data) {
      if (data === 'NONE') {
        this.formData.purposeTypeMap.marketingUse.serviceProviderLevelPrice = null
        this.formData.purposeTypeMap.marketingUse.serviceProviderId = null
        this.formData.purposeTypeMap.marketingUse.amount = null
        // this.countDifferenceAmount()
        return
      }
      this.marketingProvidersPackages.lowPrice = data.lowPrice
      this.marketingProvidersPackages.highPrice = data.highPrice
      this.marketingProvidersPackages.mediumPrice = data.mediumPrice
      this.marketingProvidersPackages.lowPriceInfo = data.lowPriceInfo
      this.marketingProvidersPackages.highPriceInfo = data.highPriceInfo
      this.marketingProvidersPackages.mediumPriceInfo = data.mediumPriceInfo
      this.formData.purposeTypeMap.marketingUse.serviceProviderLevelPrice = 'LOW_PRICE'
      this.formData.purposeTypeMap.marketingUse.serviceProviderId = data.id
      this.formData.purposeTypeMap.marketingUse.amount = data.lowPrice
      // this.countDifferenceAmount()
    },
    selectedSongProductionProvider(data) {
      if (data === 'NONE') {
        this.formData.purposeTypeMap.songProduction.serviceProviderLevelPrice = null
        this.formData.purposeTypeMap.songProduction.serviceProviderId = null
        this.formData.purposeTypeMap.songProduction.amount = null
        // this.countDifferenceAmount()
        return
      }
      this.songProvidersPackages.lowPrice = data.lowPrice
      this.songProvidersPackages.highPrice = data.highPrice
      this.songProvidersPackages.mediumPrice = data.mediumPrice
      this.songProvidersPackages.lowPriceInfo = data.lowPriceInfo
      this.songProvidersPackages.highPriceInfo = data.highPriceInfo
      this.songProvidersPackages.mediumPriceInfo = data.mediumPriceInfo
      this.formData.purposeTypeMap.songProduction.serviceProviderLevelPrice = 'LOW_PRICE'
      this.formData.purposeTypeMap.songProduction.serviceProviderId = data.id
      this.formData.purposeTypeMap.songProduction.amount = data.lowPrice
      // this.countDifferenceAmount()
    },
    selectedVideoProductionProvider(data) {
      if (data === 'NONE') {
        this.formData.purposeTypeMap.videoProduction.serviceProviderLevelPrice = null
        this.formData.purposeTypeMap.videoProduction.serviceProviderId = null
        this.formData.purposeTypeMap.videoProduction.amount = null
        // this.countDifferenceAmount()
        return
      }
      this.videoProvidersPackages.lowPrice = data.lowPrice
      this.videoProvidersPackages.highPrice = data.highPrice
      this.videoProvidersPackages.mediumPrice = data.mediumPrice
      this.videoProvidersPackages.lowPriceInfo = data.lowPriceInfo
      this.videoProvidersPackages.highPriceInfo = data.highPriceInfo
      this.videoProvidersPackages.mediumPriceInfo = data.mediumPriceInfo
      this.formData.purposeTypeMap.videoProduction.serviceProviderLevelPrice = 'LOW_PRICE'
      this.formData.purposeTypeMap.videoProduction.serviceProviderId = data.id
      this.formData.purposeTypeMap.videoProduction.amount = data.lowPrice
      // this.countDifferenceAmount()
    },
    selectedTourProductionProvider(data) {
      if (data === 'NONE') {
        this.formData.purposeTypeMap.tourProduction.serviceProviderLevelPrice = null
        this.formData.purposeTypeMap.tourProduction.serviceProviderId = null
        this.formData.purposeTypeMap.tourProduction.amount = null
        // this.countDifferenceAmount()
        return
      }
      this.tourProvidersPackages.lowPrice = data.lowPrice
      this.tourProvidersPackages.highPrice = data.highPrice
      this.tourProvidersPackages.mediumPrice = data.mediumPrice
      this.tourProvidersPackages.lowPriceInfo = data.lowPriceInfo
      this.tourProvidersPackages.highPriceInfo = data.highPriceInfo
      this.tourProvidersPackages.mediumPriceInfo = data.mediumPriceInfo
      this.formData.purposeTypeMap.tourProduction.serviceProviderLevelPrice = 'LOW_PRICE'
      this.formData.purposeTypeMap.tourProduction.serviceProviderId = data.id
      this.formData.purposeTypeMap.tourProduction.amount = data.lowPrice
      // this.countDifferenceAmount()
    },
    tokenRequest() {
      this.$load(async () => {
        let res = (await this.$api.payment.getPayment(this.paymentData))
        let token = res.data.body.redirectUrl
        if (res.data.body.status === 'PAY_AWAITING') {
          this.paymentUrl = token.split('token=')[1]
          this.stepFlag = 3
          this.loadFlag = true
          console.log('loadFlag: ', this.loadFlag)
        }
        if (res.data.body.status === 'HOLD') {
          // await this.$router.push({path: '/auction/success', query: {initialSale: 'true'}})
          await this.$router.push({
            path: `/internal-payment-handler/${this.$route.params.id}`,
            query: {redirectFrom: 'createIeo', status: 'HOLD'}
          })
        } else if (res.data.body.status !== 'HOLD' && res.data.body.status !== 'PAY_AWAITING') {
          await this.$router.push({
            path: `/internal-payment-handler/${this.$route.params.id}`,
            query: {redirectFrom: 'createIeo', status: 'FAILED'}
          })
        }
      }, error => {
        if (error.data.errors[0].id === "MANGOPAY_INITIAL_EXCEPTION" &&
            error.data.errors[0].message === 'Rate limit exceeded. Please contact support for more assistance') {
          this.$refs.mangopayCallErrorPopup.openPopup()
        }
        if (error) {
          this.errors.message = error.data.errors[0].message
          this.$refs.serverErrorPopup.openPopups()
        } else {
          // this.$router.push({path: '/profile/my-songs'})
        }
      })
    },
    changeBasicPrivateUse(el) {
      this.privatUseBasic = el.target.value
      this.privatUseBasic = +this.privatUseBasic.replace('€', '').replace('.', '').replace(',', '.').trim()
    },
    countDifferenceAmount() {
      if (this.formData.totalAmount && this.pricePerIprVal && this.creatingMoneySum && this.checkPositiveNumber) {
        let pricePerIPR = +Math.ceil((+this.creatingMoneySum / +this.formData.totalAmount) * 100) / 100

        let totalForCount = this.formData.totalAmount * pricePerIPR
        // let djookyCommission = (totalForCount * 100 - Math.round(totalForCount * 95)) / 100
        let djookyCommission = (Math.round(totalForCount * 100) - Math.round(totalForCount * 94)) / 100

        let totalPrivateUseWithMangoCommission = Math.round((
            totalForCount
            - djookyCommission
            - this.formData.purposeTypeMap.songProduction.amount
            - this.formData.purposeTypeMap.videoProduction.amount
            - this.formData.purposeTypeMap.tourProduction.amount
            - this.formData.purposeTypeMap.marketingUse.amount) * 100) / 100

        this.formData.privateUse = totalPrivateUseWithMangoCommission
        this.privateUseForShow = totalPrivateUseWithMangoCommission

        this.totalMoneyForShow = totalForCount
        this.countFee = djookyCommission

        // todo add validate on exists * - production
        // let mangoAudioProdCommission = Math.floor(
        //     (Math.round((this.formData.purposeTypeMap.songProduction / 0.99) * 100) / 100
        //         - this.formData.purposeTypeMap.songProduction)
        //     * 100) / 100

        // let mangoVideoProdCommission = Math.floor(
        //     (Math.round((this.formData.purposeTypeMap.videoProduction / 0.99) * 100) / 100
        //         - this.formData.purposeTypeMap.videoProduction)
        //     * 100) / 100

        // let mangoMarketingProdCommission = Math.floor(
        //     (Math.round((this.formData.purposeTypeMap.marketingUse / 0.99) * 100) / 100 - this.formData.purposeTypeMap.marketingUse)
        //     * 100) / 100

        // let totalPrivateUseWithMangoCommission = Math.round((
        //     totalForCount
        //     - djookyCommission
        //     - this.formData.purposeTypeMap.songProduction - mangoAudioProdCommission
        //     - this.formData.purposeTypeMap.videoProduction - mangoVideoProdCommission
        //     - this.formData.purposeTypeMap.marketingUse - mangoMarketingProdCommission) * 100) / 100

        // let mangoCommissionForPrivateUse = Math.round(
        //     (totalPrivateUseWithMangoCommission * 0.01) * 100) / 100;

        // this.formData.purposeTypeMap.privateUse = Math.round(
        //     (totalPrivateUseWithMangoCommission - mangoCommissionForPrivateUse) * 100) / 100;


        // this.countFee = totalForCount - sumField;

        // this.countFee = (
        //     djookyCommission * 100
        //     + mangoMarketingProdCommission * 100
        //     + mangoVideoProdCommission * 100
        //     + mangoAudioProdCommission * 100
        //     + mangoCommissionForPrivateUse * 100) / 100;
      }

      let timeOut

      if (this.calculateFlag) {
        clearTimeout(timeOut)
        return
      }

      timeOut = setTimeout(() => {
        this.calculateFlag = true
      }, 500)
    },
    openFullDescription() {
      this.agreeTextShow = this.agreeText
    },
    onSubmit() {
      if (this.checkPositiveNumber) {
        // this.countDifferenceAmount()
        this.$load(async () => {
          await this.$api.createIeo.create(this.formData)
          this.firstCheckBox = false
          this.$refs.createIeoCheckboxFirst.checkInFalse()
          this.stepFlag = 2
        }, error => {
          if (error) {
            if (error.data.errors[0].id === 'ALREADY_EXISTS') {
              this.$load(async () => {
                await this.$api.createIeo.updateIeo(this.formData)
                this.firstCheckBox = false
                this.$refs.createIeoCheckboxFirst.checkInFalse()
                this.stepFlag = 2
                return
              }, error => {
                this.errors.message = error.data.errors[0].message
                this.$refs.serverErrorPopup.openPopups()
              })
              return
            }
            if (error.data.errors[0].id === "HTTP_MESSAGE_NOT_READABLE" || error.data.errors[0].id === 'ILLEGAL_FORMAT_EXCEPTION' || error.data.errors[0].id === 'DATA_INTEGRITY_VIOLATION') {
              this.errors.message = 'Data is not valid'
              this.$refs.serverErrorPopup.openPopups()
              return
            }
            this.errors.message = error.data.errors[0].message
            this.$refs.serverErrorPopup.openPopups()
          } else {
            // this.$router.push({path: '/profile/my-songs'})
          }
        })
      } else {
        this.goTo('privatUseTitle')
      }
    },
    documentSignedAgreed(data) {
      const queryAdminId = this.$route.query.adminId
      let params = {
        ieoUuid: this.$route.params.id,
        songAdminId: queryAdminId ? +queryAdminId : this.formData.ieoAdminId
      }
      this.$load(async () => {
        let res = await this.$api.payment.getFinancialSettingAmount()
        this.paymentData.price = res.data.body.amount
        this.paymentData.initialFinancialType = res.data.body.initialFinancialType
        if (queryAdminId) {
          this.loadFlag = false
          this.paymentData.paymentType = data
          this.tokenRequest()
        } else {
          this.$api.contract.adminPlatformContract(params).then(() => {
            this.paymentData.paymentType = data
            this.tokenRequest()
          })
        }

      })
    },
    documentSignedAgreedWidthWallet(data) {
      this.paymentData.paymentType = data
      this.tokenRequest()
    },
    getQuery(urlString, query) {
      let uri = urlString.split('?');
      if (uri.length === 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function (v) {
          tmp = v.split('=');
          if (tmp.length === 2)
            getVars[tmp[0]] = tmp[1];
        });
        if (query === 'transactionId') {
          return getVars.transactionId
        }
        if (query === 'status') {
          return getVars.status
        }
      }
    },
    getProviderList() {
      this.$load(async () => {
        let res = await this.$api.createIeo.getServiceProviders()
        this.songProviders = res.data.body.providerInfo.songProduction.data
        this.marketingProviders = res.data.body.providerInfo.marketingUse.data
        this.videoProviders = res.data.body.providerInfo.videoProduction.data
        this.tourProviders = res.data.body.providerInfo.tourProduction.data
      })
    },
    getPrivateUseList() {
      this.$load(async () => {
        let res = await this.$api.createIeo.getNeedsList()
        let list = res.data.body.purposes
        this.needsList = list.map((item) => {
          return {
            name: item.name,
            value: item.type
          }
        })
        this.currentNeed = this.needsList[0]
        // this.needsList.unshift(this.defaultNeedItem)
      })
    },
    setNeeds(data) {
      if (data !== null && data !== '' && typeof data === 'string') {
        this.formData.privateUsePurposes = [data]
      } else {
        this.formData.privateUsePurposes = []
      }
    },
    lengthWatcher() {
      window.addEventListener("resize", () => {
        if (window.innerWidth >= 1024) {
          this.screenWidthFlag = 1
          return
        }
        if (window.innerWidth < 1024) {
          this.screenWidthFlag = 0
        }
      }, false);
    },
    goTo(refName) {
      let element = this.$refs[refName]
      element.scrollIntoView({behavior: 'smooth'})
    },
    checkedChangeFirst(data) {
      this.firstCheckBox = data
    },
    getAdminTypeList() {
      this.$load(async () => {
        let res = await this.$api.ieoSong.getIeoAdminTypes()
        this.adminTypeList = res.data.body.elements
        this.formData.ieoAdminId = this.adminTypeList[0].id
      })
    }
  },
  watch: {
    calculateFlag(newVal) {
      console.log('calculateFlag: ', newVal)
    },
    'formData.privateUse'() {
      this.calculateFlag = false
    },
    creatingMoneySum(newVal) {
      if (newVal === 0) {
        this.totalMoneyForShow = 0
        this.formData.privateUse = 0
        this.countFee = 0
      }
    },
    'formData.purposeTypeMap.marketingUse.serviceProviderLevelPrice'(newVal) {
      this.calculateFlag = false
      switch (newVal) {
        case 'LOW_PRICE':
          this.formData.purposeTypeMap.marketingUse.amount = this.marketingProvidersPackages.lowPrice
          // this.countDifferenceAmount()
          break;
        case 'MEDIUM_PRICE':
          this.formData.purposeTypeMap.marketingUse.amount = this.marketingProvidersPackages.mediumPrice
          break
        case 'HIGH_PRICE':
          this.formData.purposeTypeMap.marketingUse.amount = this.marketingProvidersPackages.highPrice
          break
      }
    },
    'formData.purposeTypeMap.songProduction.serviceProviderLevelPrice'(newVal) {
      this.calculateFlag = false
      switch (newVal) {
        case 'LOW_PRICE':
          this.formData.purposeTypeMap.songProduction.amount = this.songProvidersPackages.lowPrice
          break;
        case 'MEDIUM_PRICE':
          this.formData.purposeTypeMap.songProduction.amount = this.songProvidersPackages.mediumPrice
          break
        case 'HIGH_PRICE':
          this.formData.purposeTypeMap.songProduction.amount = this.songProvidersPackages.highPrice
          break
      }
    },
    'formData.purposeTypeMap.videoProduction.serviceProviderLevelPrice'(newVal) {
      this.calculateFlag = false
      switch (newVal) {
        case 'LOW_PRICE':
          this.formData.purposeTypeMap.videoProduction.amount = this.videoProvidersPackages.lowPrice
          break;
        case 'MEDIUM_PRICE':
          this.formData.purposeTypeMap.videoProduction.amount = this.videoProvidersPackages.mediumPrice
          break
        case 'HIGH_PRICE':
          this.formData.purposeTypeMap.videoProduction.amount = this.videoProvidersPackages.highPrice
          break
      }
    },
    'formData.purposeTypeMap.tourProduction.serviceProviderLevelPrice'(newVal) {
      this.calculateFlag = false
      switch (newVal) {
        case 'LOW_PRICE':
          this.formData.purposeTypeMap.tourProduction.amount = this.tourProvidersPackages.lowPrice
          break;
        case 'MEDIUM_PRICE':
          this.formData.purposeTypeMap.tourProduction.amount = this.tourProvidersPackages.mediumPrice
          break
        case 'HIGH_PRICE':
          this.formData.purposeTypeMap.tourProduction.amount = this.tourProvidersPackages.highPrice
          break
      }
    },
    pricePerIprVal(newVal) {
      this.formData.pricePerIpr = newVal
    },

    totalDifference(newVal, oldVal) {
      if (newVal <= 0) {
        this.totalDifference = oldVal
      }
    },
    totalIPR(newVal, oldVal) {
      this.calculateFlag = false
      if (newVal === '' || Number(newVal) === 0) {
        this.totalMoneyForShow = 0
        this.formData.privateUse = 0
        this.countFee = 0
        this.formData.privateUse = this.privatUseBasic
        this.percentWatcher = 0
      } else {
        this.percentWatcher = 1
      }
      if (typeof newVal === 'string') {
        if (newVal.includes('.')) {
          let newValSplit = newVal.split('.')[1]
          if (newValSplit.length >= 4) {
            this.totalIPR = oldVal
          }
        }
      }
      if (Number(newVal)) {
        if (newVal > 0)
          if (newVal < 50) {
            {
              this.formData.totalAmount = Math.round(1000 * newVal)
            }
          }
      }
    },

    'formData.totalAmount'(newVal) {
      this.totalAmountShow = this.numberFormattingInteger(newVal)
      if (newVal === '') {
        setTimeout(() => {
          newVal = 0
          this.totalAmountShow = 0
        }, 0)
      }
    },
    'formData.pricePerIpr'(newVal, oldVal) {
      let maxPrice = this.$refs["totalPerOne"]
      let regex = new RegExp(maxPrice.pattern, 'g');
      setTimeout(() => {
        if (!regex.test(newVal)) {
          this.formData.pricePerIpr = oldVal
        }
      }, 0)
    }
  },
  computed: {
    checkForDisable() {
      return !this.firstCheckBox || this.disableSubmitBtn || !this.calculateFlag
    },
    toggleTooltipShowEvent() {
      return this.screenWidthFlag > 0
    },
    readonly() {
      return this.state
    },
    // redundant
    pricePerIprVal() {
      if (this.formData.totalAmount === 0) {
        return 0
      }

      return Math.ceil((+this.creatingMoneySum / +this.formData.totalAmount * this.percentWatcher) * 100) / 100 || 0
    },
    // redundant
    creatingMoneySum() {
      // let privat = (+this.privatUseBasic / 0.99 / 0.95)
      // let audio = (+this.formData.purposeTypeMap.songProduction / 0.99 / 0.95)
      // let video = (+this.formData.purposeTypeMap.videoProduction / 0.99 / 0.95)
      // let marketing = (+this.formData.purposeTypeMap.marketingUse / 0.99 / 0.95)

      let privat = (+this.privatUseBasic)
      let audio = (+this.formData.purposeTypeMap.songProduction.amount)
      let video = (+this.formData.purposeTypeMap.videoProduction.amount)
      let marketing = (+this.formData.purposeTypeMap.marketingUse.amount)
      let tour = (+this.formData.purposeTypeMap.tourProduction.amount)

      // let sum = privat + audio + video + marketing
      let sum = Math.round((privat * 100 + audio * 100 + video * 100 + marketing * 100 + tour * 100) / 0.94)
          / 100;

      return sum
    },
    // redundant
    sellSumValue() {
      let sum = this.formData.totalAmount * this.formData.pricePerIpr
      return new Intl.NumberFormat().format(sum)
    },
    checkPositiveNumber() {
      return this.formData.privateUse >= 0
    },
    marketingLowPriceToFormat() {
      return this.numberFormattingInteger(this.marketingProvidersPackages.lowPrice)
    },
    songLowPriceToFormat() {
      return this.numberFormattingInteger(this.songProvidersPackages.lowPrice)
    },
    videoLowPriceToFormat() {
      return this.numberFormattingInteger(this.videoProvidersPackages.lowPrice)
    },
    tourLowPriceToFormat() {
      return this.numberFormattingInteger(this.tourProvidersPackages.lowPrice)
    },
    marketingMediumPriceToFormat() {
      return this.numberFormattingInteger(this.marketingProvidersPackages.mediumPrice)
    },
    songMediumPriceToFormat() {
      return this.numberFormattingInteger(this.songProvidersPackages.mediumPrice)
    },
    videoMediumPriceToFormat() {
      return this.numberFormattingInteger(this.videoProvidersPackages.mediumPrice)
    },
    tourMediumPriceToFormat() {
      return this.numberFormattingInteger(this.tourProvidersPackages.mediumPrice)
    },
    marketingHighPriceToFormat() {
      return this.numberFormattingInteger(this.marketingProvidersPackages.highPrice)
    },
    songHighPriceToFormat() {
      return this.numberFormattingInteger(this.songProvidersPackages.highPrice)
    },
    videoHighPriceToFormat() {
      return this.numberFormattingInteger(this.videoProvidersPackages.highPrice)
    },
    tourHighPriceToFormat() {
      return this.numberFormattingInteger(this.tourProvidersPackages.highPrice)
    },
    countFeeToFormat() {
      return this.numberFormattingDecimal(this.countFee)
    },
    totalAmountToFormat() {
      return this.numberFormattingInteger(this.formData.totalAmount)
    },
    totalMoneyToFormat() {
      return this.numberFormattingDecimal(this.totalMoneyForShow)
    },
    privateUseToFormat() {
      return this.numberFormattingDecimal(this.privateUseForShow)
    },
  },
  created() {
    this.getAdminTypeList()
  },
  mounted() {
    if (this.getQuery(this.$route.fullPath, 'status') === 'PAY_AWAITING') {
      this.documentSignedAgreed('CARD')
      this.songStatus = 'PAY_AWAITING'
    } else if (this.getQuery(this.$route.fullPath, 'status') === 'SIGN_AWAITING') {
      this.stepFlag = 2
      this.songStatus = 'SIGN_AWAITING'
    }

    this.lengthWatcher()
    setTimeout(() => {
      this.getProviderList()
      this.getPrivateUseList()
    }, 500)

    this.formData.songUuid = this.$route.params.id
    if (window.innerWidth >= 1025) {
      this.screenWidthFlag = 1
      return
    }
    if (window.innerWidth < 1025) {
      this.screenWidthFlag = 0
    }
  },
  components: {
    Footer,
    Header,
    Sidebar,
    // Money,
    ServerErrorPopup,
    GeneralCustomCheckBox,
    StepProgressBar,
    IeoCreatingDocumentSign,
    IeoCreatingPayment,
    CustomSelectForCreatePm,
    MangopayCallErrorPopup,
    GeneralSelect,
    CustomSelectForAdminType
  }
}
</script>

<style scoped>

/*.asterisk {*/
/*  color: red;*/
/*}*/

/*.width100 {*/
/*  width: 100%;*/
/*}*/

.error {
  font-size: 12px;
  color: #ff0090;
}

/*.commission-fee {*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 20px;*/
/*  line-height: 23px;*/
/*  color: #131415;*/
/*}*/

.ieo-creating-money-item-input.commission-block {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #131415;
  padding: 14px 30px 14px 45px;
  position: relative;
  background: #F9F9F9;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
}

.ieo-creating-admin-input {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #5A5A5A;
  background: #F9F9F9;
  border: 1px solid #F9F9F9;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 14px 30px;
}

.ieo-creating-money-select-wrapper {
  width: calc(100% - 315px);
}

.package-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 13px 60px 13px 30px;
  background: #F9F9F9;
  border: 1px solid #C6C6C6;
  border-top: unset;
  border-radius: 0 0 5px 5px;
}

.package-list-item {
  display: flex;
  align-items: center;
}

.package-list-item span {
  margin-left: 8px;
}

/*.package-list-item-name {*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 18px;*/
/*  line-height: 21px;*/
/*  color: #5A5A5A;*/
/*}*/

.package-list-item-amount {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #131415;
}

.package-list-info-item {
  fill: black;
  opacity: 0.2;
  transition: .5s ease;
}

.package-list-info-item:hover {
  opacity: 1;
}

.package-list-item-border {
  position: relative;
  width: 24px;
  height: 24px;
  background: #FFFFFF;
  border: 1px solid #C6C6C6;
  border-radius: 50%;
  margin-bottom: 0;
  cursor: pointer;
}

.package-list-item-border .package-list-item-fill {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background: #FF0090;
  border-radius: 50%;
}

.package-list-item-border input {
  width: 0;
  height: 0;
}

.error-message.absolute {
  margin-bottom: 0;
  color: red;
  position: absolute;
  bottom: -25px;
  left: 200px;
}

.tooltip-anchor {
  position: relative;
}

.ieo-creating-money-item-other button.v-btn {
  position: absolute;
  top: -15px;
  right: -9px;
  height: unset;
  min-width: unset;
  padding: 0 0 0 5px;
  background-color: transparent;
  text-transform: unset;
  box-shadow: unset;
}

@media only screen and (max-width: 1300px) {
  .ieo-creating-title.ieo-creating-title__rights {
    width: calc(100% - 30px);
  }
}

@media only screen and (max-width: 1025px) {
  .ieo-creating-money-item-other button.v-btn {
    top: 0;
    right: 0;
  }
}

@media only screen and (max-width: 1100px) {


  .error-message.absolute {
    left: 0;
  }

  .ieo-creating-money-item-title {
    width: 100%;
    margin-bottom: 15px;
  }

  .ieo-creating-money-select-wrapper {
    width: 100%;
  }

  .ieo-creating-money-item-input.money-input {
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .package-list {
    padding: 13px 30px 13px 20px;
  }

  .package-list-item-amount, .package-list-item-name {
    font-size: 16px;
  }

}

@media only screen and (max-width: 630px) {
  .package-list-item-amount, .package-list-item-name {
    font-size: 16px;
  }

  .package-list-item {
    width: 100%;
    margin-bottom: 30px;
  }

  .package-list-item:last-child {
    margin-bottom: 0;
  }
}
</style>