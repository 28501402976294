export default function(instance) {
    return {
        getSongAuth(songId) {
            return instance.get(`/api/v2/ieo/song/authorized/${songId}`)
        },
        getSongNotAuth(songId) {
            return instance.get(`/api/v2/ieo/song/unauthorized/${songId}`)
        },
        buyIeo(data) {
            return instance.put('/api/v2/ieo/balance', data)
        },
        getBankAndAddressDetails(userUuid) {
            return instance.get(`/api/v2/accounts/legal/details/${userUuid}`)
        },
        getIeoAdminTypes() {
            return instance.get('/api/v2/ieo/admin-types')
        }
    }
}