<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <div class="profile">
        <div class="container-test">

        </div>
      </div>
    </main>
    <Footer/>
  </div>

</template>
<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default {
  name: "Test",
  data() {
    return {
      user: {}
    }
  },
  methods: {

  },
  computed: {

  },
  watch: {

  },
  mounted() {


  },
  components: {
    Header,
    Footer,
  }
}

</script>

<style scoped>

.container-test {
  display: flex;
  justify-content: center;
  padding: 100px 0;
  width: 85%;
  height: 100vh;
  margin: 0 auto;
}

</style>