import { render, staticRenderFns } from "./NewsListTemplate.vue?vue&type=template&id=0cae8549&scoped=true"
import script from "./NewsListTemplate.vue?vue&type=script&lang=js"
export * from "./NewsListTemplate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cae8549",
  null
  
)

export default component.exports